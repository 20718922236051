import React, { useContext } from 'react'
import { AppContext } from "@/Contexts/app-context"
import Tabs from "@/Components/Tabs"
import CategoryTab from "@/Components/Vibeset/CustomisationTabs/CategoryTab"
import VibesetBill from "@/Components/Vibeset/VibesetBill/VibesetBill"
import MobileVibesetBill from "@/Components/Vibeset/VibesetBill/MobileVibesetBill"

const CustomisationTabs = ({product_section, index}) => {
    const {appContext, setAppContext} = useContext(AppContext)

    // Update active tab
    const handleUpdateActiveTabIndex = (index) => {
        setAppContext({
            ...appContext,
            activeProductCategoryIndex: index,
        })
    }

    // Only show selected customisation tabs
    if (appContext?.activeProductSectionIndex !== index) {
        return null
    }

    return (
        <div>
            <div className="flex items-center flex-wrap mb-4 laptop:mb-5">
                <h1 className="text-black text-h3 leading-h3 font-abril mr-4 mb-4 tablet:mb-2">
                    Customise your {product_section.name.toLowerCase()}
                </h1>
            </div>

            <div className="navbar:hidden">
                <MobileVibesetBill products={appContext.products} is_summary={false} is_read_only={false}/>
            </div>

            <Tabs
                activeTabIndex={appContext.activeProductCategoryIndex}
                setActiveTabIndex={handleUpdateActiveTabIndex}
            >
                {product_section.product_categories.map((product_category, index) => (
                    <Tabs.Tab
                        key={`product-category-tab-${index}`}
                        title={product_category.name}
                    >
                        <CategoryTab
                            key={`vibeset-customisation-category-${index}`}
                            product_category={product_category}
                            isLastTab={index === product_section.product_categories.length - 1}
                        />
                    </Tabs.Tab>
                ))}
            </Tabs>
        </div>
    )
}

export default CustomisationTabs
