import React from "react";

export const StripeIcon = ({className}) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 468 222.5">
        <g>
            <path fillRule="evenodd" clipRule="evenodd" fill="currentColor" d="M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3
		c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z
		 M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="currentColor" d="M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3
		c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1
		c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z"/>
            <polygon fillRule="evenodd" clipRule="evenodd" fill="currentColor"
                     points="223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 	"/>
            <rect x="223.8" y="69.3" fillRule="evenodd" clipRule="evenodd" fill="currentColor" width="25.1"
                  height="87.5"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="currentColor"
                  d="M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="currentColor" d="M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135
		c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="currentColor" d="M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6
		C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7
		c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z"/>
        </g>
    </svg>
)
export const UAEFlagIcon = ({className}) => (
    <svg id="uae_flag_icon" xmlns="http://www.w3.org/2000/svg" width="20.001" height="13.226"
         viewBox="0 0 20.001 13.226" className={className}>
        <rect id="Rectangle_758" data-name="Rectangle 758" width="5.324" height="13.226" fill="red"/>
        <rect id="Rectangle_759" data-name="Rectangle 759" width="14.676" height="4.409" transform="translate(5.324)"
              fill="#00843d"/>
        <rect id="Rectangle_760" data-name="Rectangle 760" width="14.676" height="4.409"
              transform="translate(5.324 4.408)" fill="#fff"/>
        <rect id="Rectangle_761" data-name="Rectangle 761" width="14.676" height="4.409"
              transform="translate(5.324 8.816)"/>
    </svg>
)

export const MasterCardIcon = ({className}) => (
    <svg id="Mastercard" xmlns="http://www.w3.org/2000/svg" width="55.123" height="34.451" viewBox="0 0 55.123 34.451"
         className={className}>
        <g id="Rectangle_412" data-name="Rectangle 412" transform="translate(-541.246 -320.884)">
            <rect id="Rectangle_477" data-name="Rectangle 477" width="55.123" height="34.451"
                  transform="translate(541.246 320.884)" fill="none"/>
            <rect id="Rectangle_478" data-name="Rectangle 478" width="52.123" height="31.451"
                  transform="translate(542.746 322.384)" fill="none" stroke="#ededed" strokeWidth="3"/>
        </g>
        <g id="Group_494" data-name="Group 494" transform="translate(-541.246 -320.884)">
            <path id="Path_952" data-name="Path 952"
                  d="M575.423,348l.51-.013.5-.037.495-.063.489-.087.478-.11.47-.133.46-.157.45-.177.44-.2.427-.217.414-.241.4-.257.387-.277.373-.294.361-.31.344-.33.33-.344.31-.361.294-.373.277-.387.257-.4.24-.414.217-.427.2-.44.177-.451.156-.46.134-.47.109-.477.087-.49.063-.494.037-.5.013-.51-.013-.507-.037-.5-.063-.494-.087-.484-.109-.477-.134-.467-.156-.46-.177-.447-.2-.437-.217-.427-.24-.41-.257-.4-.277-.387-.294-.37-.31-.36-.33-.344-.344-.327-.361-.31-.373-.294-.387-.277-.4-.257-.414-.237-.427-.216-.44-.2-.45-.177-.46-.157-.47-.133-.478-.11-.489-.086-.495-.064-.5-.037-.51-.013-.508.013-.5.037-.493.064-.488.086-.477.11-.469.133-.458.157-.45.177-.437.2-.424.216-.413.237-.4.257-.386.277-.374.294-.357.31-.344.327-.327.344-.311.36-.293.37-.273.387-.257.4-.237.41-.22.427-.2.437-.177.447-.153.46-.134.467-.11.477-.087.484-.063.494-.037.5-.013.507.013.51.037.5.063.494.087.49.11.477.134.47.153.46.177.451.2.44.22.427.237.414.257.4.273.387.293.373.311.361.327.344.344.33.357.31.374.294.386.277.4.257.413.241.424.217.437.2.45.177.458.157.469.133.477.11.488.087.493.063.5.037Z"
                  fill="#fbb231" fillRule="evenodd"/>
            <path id="Path_953" data-name="Path 953"
                  d="M562.2,348l.506-.013.5-.037.494-.063.487-.087.478-.11.47-.133.457-.157.449-.177.438-.2.423-.217.414-.241.4-.257.388-.277.374-.294.357-.31.344-.33.327-.344.311-.361.292-.373.275-.387.256-.4.238-.414.22-.427.2-.44.177-.451.153-.46.133-.47.111-.477.087-.49.062-.494.037-.5.014-.51-.014-.507-.037-.5-.062-.494-.087-.484-.111-.477-.133-.467-.153-.46-.177-.447-.2-.437-.22-.427-.238-.41-.256-.4-.275-.387-.292-.37-.311-.36-.327-.344-.344-.327-.357-.31-.374-.294-.388-.277-.4-.257-.414-.237-.423-.216-.438-.2-.449-.177-.457-.157-.47-.133-.478-.11-.487-.086-.494-.064-.5-.037-.506-.013-.511.013-.5.037-.494.064-.491.086-.477.11-.47.133-.46.157-.449.177-.44.2-.427.216-.413.237-.4.257-.387.277-.373.294-.36.31-.343.327-.33.344-.31.36-.293.37-.277.387-.257.4-.24.41-.216.427-.2.437-.177.447-.156.46-.133.467-.11.477-.087.484-.064.494-.036.5-.013.507.013.51.036.5.064.494.087.49.11.477.133.47.156.46.177.451.2.44.216.427.24.414.257.4.277.387.293.373.31.361.33.344.343.33.36.31.373.294.387.277.4.257.413.241.427.217.44.2.449.177.46.157.47.133.477.11.491.087.494.063.5.037Z"
                  fill="#ec1c2e" fillRule="evenodd"/>
            <path id="Path_954" data-name="Path 954" d="M567.65,332.013H572.9v-.537h-4.8Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_955" data-name="Path 955" d="M566.863,333.207H572.9v-.537H567.18Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_956" data-name="Path 956" d="M566.276,334.4H572.9v-.537h-6.4l-.234.537Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_957" data-name="Path 957" d="M565.872,335.592H572.9v-.537h-6.865Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_958" data-name="Path 958" d="M566.1,341.364h6.815v-.537h-6.982l.167.537Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_959" data-name="Path 959" d="M566.609,342.557h6.312v-.537h-6.562Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_960" data-name="Path 960" d="M567.316,343.748h5.6v-.537h-5.941l.336.537Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_961" data-name="Path 961" d="M568.291,344.943h4.63V344.4h-5.1Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_962" data-name="Path 962" d="M565.636,336.784h7.269v-.537h-7.166l-.1.537Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_963" data-name="Path 963" d="M570.065,340.173h2.772v-.533h-2.672l-.1.538Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_964" data-name="Path 964" d="M570.287,338.981h2.552v-.54h-2.453Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_965" data-name="Path 965" d="M565.451,338.441h.721v.54h-.687l-.034-.54Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_966" data-name="Path 966"
                  d="M564.107,337.79h-.046l-.047-.006-.044-.006-.042-.007-.041-.006-.043-.007-.042-.006-.047-.007-.047-.006h-.323l-.087.007-.1.006-.067.01-.063.01-.057.02-.051.02-.047.024-.042.027-.035.034-.03.03-.02.037-.017.037-.007.04v.04l.01.044.013.043.026.044.034.043.067.063.082.05.093.043.1.037.107.034.11.037.11.04.107.043.1.057.094.07.083.084.07.1.053.127.034.15.013.18-.013.21-.023.157-.04.137-.054.127-.066.11-.076.1-.087.083-.094.073-.1.064-.108.05-.109.043-.114.034-.116.027-.114.021-.11.01-.109.007h-.7l-.09-.007-.08-.007-.077-.01-.071-.007-.063-.013-.063-.01-.057-.016-.057-.014-.053-.02-.054-.02-.057-.02.15-.737.03.007.036.01.047.013.057.014.06.013.067.013.073.017.08.014.083.013.087.013.09.01.094.01h.4l.1-.02.077-.027.06-.036.047-.047.033-.047.027-.054.017-.053.013-.05.006-.1-.03-.083-.057-.067-.083-.056-.1-.05-.116-.05-.13-.047-.13-.053-.134-.06-.127-.07-.116-.087-.108-.1-.083-.127-.06-.15-.03-.18v-.214l.017-.13.024-.124.033-.113.04-.107.053-.1.06-.087.07-.084.083-.073.1-.064.106-.054.12-.05.134-.037.151-.03.163-.023.18-.013h.63l.083.006.077.007.067.007.06.007.058.006.046.01.043.007.041.006.034.006h.03l.03.007h.027l-.171.753Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_967" data-name="Path 967"
                  d="M564.107,337.79h-.046l-.047-.006-.044-.006-.042-.007-.041-.006-.043-.007-.042-.006-.047-.007-.047-.006h-.323l-.087.007-.1.006h0l-.067.01-.063.01-.057.02-.051.02-.047.024-.042.027-.035.034-.03.03-.02.037-.017.037-.007.04v.04l.01.044.013.043.026.044.034.043h0l.067.063.082.05.093.043.1.037.107.034.11.037.11.04.107.043.1.057.094.07.083.084.07.1.053.127.034.15.013.18-.013.21h0l-.023.157-.04.137-.054.127-.066.11-.076.1-.087.083-.094.073-.1.064-.108.05-.109.043-.114.034-.116.027-.114.021-.11.01-.109.007h-.7l-.09-.007-.08-.007-.077-.01-.071-.007-.063-.013-.063-.01-.057-.016-.057-.014-.053-.02-.054-.02-.057-.02.15-.737h0l.03.007.036.01.047.013.057.014.06.013.067.013.073.017.08.014.083.013.087.013.09.01.094.01h.4l.1-.02.077-.027.06-.036.047-.047.033-.047.027-.054.017-.053.013-.05h0l.006-.1-.03-.083-.057-.067-.083-.056-.1-.05-.116-.05-.13-.047-.13-.053-.134-.06-.127-.07-.116-.087-.108-.1-.083-.127-.06-.15-.03-.18v-.214h0l.017-.13.024-.124.033-.113.04-.107.053-.1.06-.087.07-.084.083-.073.1-.064.106-.054.12-.05.134-.037.151-.03.163-.023.18-.013h.63l.083.006.077.007.067.007.06.007.058.006.046.01.043.007.041.006.034.006h.03l.03.007h.027l-.171.753"
                  fill="none" stroke="#fff" strokeMiterlimit="2.613" strokeWidth="0.216"/>
            <path id="Path_968" data-name="Path 968"
                  d="M564.9,336.213h.924l-.137.807h.557l-.12.754-.588-.017-.32,1.861v.067l.016.057.033.043.044.036.06.024.067.02.073.013.08.007h.073l.066-.007.061-.01.053-.013.044-.013.03-.01.02-.007h.006l-.116.621-.022.017-.023.013-.023.013-.023.013-.024.01-.027.01-.03.01-.031.01-.033.01-.034.007-.04.007-.04.007-.043.007-.05.006-.054.006-.057.007h-.254l-.134-.01-.116-.013-.1-.02-.09-.024-.077-.03-.063-.033-.057-.04-.043-.044-.033-.047-.024-.057-.016-.057-.01-.063v-.143l.01-.077.01-.084.637-3.639Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_969" data-name="Path 969"
                  d="M564.9,336.213h.924l-.137.807h.557l-.12.754-.588-.017-.32,1.861h0v.067l.016.057.033.043.044.036.06.024.067.02.073.013.08.007h.073l.066-.007.061-.01.053-.013.044-.013.03-.01.02-.007h.006l-.116.621h0l-.022.017-.023.013-.023.013-.023.013-.024.01-.027.01-.03.01-.031.01-.033.01-.034.007-.04.007-.04.007-.043.007-.05.006-.054.006-.057.007h-.254l-.134-.01-.116-.013-.1-.02-.09-.024-.077-.03-.063-.033-.057-.04-.043-.044-.033-.047-.024-.057-.016-.057-.01-.063v-.143l.01-.077.01-.084.637-3.639"
                  fill="none" stroke="#fff" strokeMiterlimit="2.613" strokeWidth="0.216"/>
            <path id="Path_970" data-name="Path 970"
                  d="M566.98,339.031h0l-.01.056v.06l.006.064.013.064.023.063.027.061.036.063.04.056.047.057.05.05.054.05.059.04.061.037.067.03.066.023.067.014h0l.087.013.083.01.088.01.086.007h.263l.09-.007.09-.013.09-.017.093-.02.09-.03.094-.036.1-.041.094-.053.1-.057-.133.821h0l-.054.026-.053.027-.053.02-.051.021-.05.016-.054.017-.053.013-.057.01-.06.01-.067.007-.07.007-.077.006h-.549l-.167-.017-.167-.024-.167-.04-.163-.05-.157-.064-.151-.083-.14-.1-.13-.113-.113-.137-.1-.154-.082-.177-.056-.2-.036-.224-.01-.247.017-.273h0l.013-.107.02-.124.027-.13.036-.141.051-.143.059-.144.077-.147.094-.14.107-.134.13-.127.147-.113.167-.1.193-.084.217-.06.239-.04.271-.014h.13l.137.013.137.023.137.033.133.051.131.06.12.08.11.093.1.114.084.134.066.153.047.177.027.2v.227l-.023.254-.051.281-.016.2h-2.431l.153-.657h1.461l.007-.083v-.08l-.007-.074-.013-.066-.023-.063-.022-.057-.035-.05-.033-.047-.038-.04-.044-.033-.05-.03-.05-.027-.053-.02-.053-.014-.057-.01-.061-.007h-.063l-.06.01-.057.014-.057.02-.057.027-.049.03-.051.037-.043.04-.043.047-.041.05-.036.054-.03.057-.03.06-.023.067-.02.067-.014.07-.133.69"
                  fill="none" stroke="#fff" strokeMiterlimit="2.613" strokeWidth="0.216"/>
            <path id="Path_971" data-name="Path 971"
                  d="M574.936,337.22l-.043-.023-.043-.02-.047-.023-.05-.023-.051-.02-.056-.02-.057-.02-.06-.02-.063-.017-.064-.013-.069-.013-.07-.01-.077-.007-.077-.006h-.247l-.087.006-.09.01-.093.016-.094.027-.093.037-.094.047-.094.06-.09.074-.087.087-.083.107-.079.123-.074.147-.069.167-.064.188-.053.213-.021.12-.013.117-.007.117v.114l.009.113.023.107.03.1.037.1.05.09.06.086.071.077.08.07.09.06.1.05.114.043.127.03.044.01.05.01.06.007.067.007.073.007h.16l.084-.007.087-.011.087-.016.083-.023.083-.026.083-.037.077-.043.07-.053.067-.06-.15.891-.021.017-.019.02-.027.024-.03.019-.037.027-.047.024-.054.023-.06.02-.077.024-.087.017-.1.014-.117.013-.13.006h-.321l-.187-.01-.153-.017-.153-.033-.153-.05-.153-.067-.147-.08-.14-.1-.133-.116-.124-.133-.11-.15-.093-.167-.08-.187-.06-.2-.04-.224-.014-.237.007-.26.036-.277.027-.137.029-.133.034-.13.034-.123.036-.117.043-.116.044-.11.046-.1.051-.1.05-.1.057-.093.06-.087.061-.084.066-.08.067-.073.07-.07.076-.067.077-.063.08-.057.083-.053.09-.05.09-.043.094-.04.1-.037.1-.033.1-.027.107-.024.114-.02.113-.017.12-.013.12-.006h.234l.1.006.1.01.1.014.094.013.09.02.087.017.08.02.077.02.067.02.063.02.053.02.047.017.04.014.03.013.021.007-.168.924Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_972" data-name="Path 972"
                  d="M574.936,337.22h0l-.043-.023-.043-.02-.047-.023-.05-.023-.051-.02-.056-.02-.057-.02-.06-.02-.063-.017-.064-.013-.069-.013-.07-.01-.077-.007-.077-.006h-.247l-.087.006-.09.01-.093.016-.094.027-.093.037-.094.047-.094.06-.09.074-.087.087-.083.107-.079.123-.074.147-.069.167-.064.188-.053.213h0l-.021.12-.013.117-.007.117v.114l.009.113.023.107.03.1.037.1.05.09.06.086.071.077.08.07.09.06.1.05.114.043.127.03h0l.044.01.05.01.06.007.067.007.073.007h.16l.084-.007.087-.011.087-.016.083-.023.083-.026.083-.037.077-.043.07-.053.067-.06-.15.891h0l-.021.017-.019.02-.027.024-.03.019-.037.027-.047.024-.054.023-.06.02-.077.024-.087.017-.1.014-.117.013-.13.006h-.321l-.187-.01h0l-.153-.017-.153-.033-.153-.05-.153-.067-.147-.08-.14-.1-.133-.116-.124-.133-.11-.15-.093-.167-.08-.187-.06-.2-.04-.224-.014-.237.007-.26.036-.277h0l.027-.137.029-.133.034-.13.034-.123.036-.117.043-.116.044-.11.046-.1.051-.1.05-.1.057-.093.06-.087.061-.084.066-.08.067-.073.07-.07.076-.067.077-.063.08-.057.083-.053.09-.05.09-.043.094-.04.1-.037.1-.033.1-.027.107-.024.114-.02.113-.017.12-.013.12-.006h.234l.1.006.1.01.1.014.094.013.09.02.087.017.08.02.077.02.067.02.063.02.053.02.047.017.04.014.03.013.021.007-.168.924"
                  fill="none" stroke="#fff" strokeMiterlimit="2.613" strokeWidth="0.216"/>
            <path id="Path_973" data-name="Path 973"
                  d="M583.148,340.593h-.923l.067-.37v-.034l-.054.067-.057.06-.056.053-.061.047-.06.043-.06.036-.06.03-.063.03-.063.023-.063.017-.063.017-.067.01-.066.011-.063.007H581.3l-.174-.014-.16-.037-.147-.057-.134-.076-.12-.1-.107-.113-.094-.124-.08-.14-.066-.151-.054-.156-.037-.163-.027-.17-.01-.17V339l.017-.17.03-.17.05-.2.06-.186.071-.17.083-.16.087-.15.1-.134.1-.12.11-.11.117-.1.123-.083.127-.073.127-.057.133-.047.133-.03.133-.02.137-.007h.1l.093.01.087.017.08.024.077.027.067.029.068.037.06.037.053.039.05.044.044.043.04.043.033.043.03.043.027.04.02.04h0l.234-1.311h.907l-.754,4.363Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_974" data-name="Path 974"
                  d="M583.148,340.593h-.923l.067-.37v-.034h0l-.054.067-.057.06-.056.053-.061.047-.06.043-.06.036-.06.03-.063.03-.063.023-.063.017-.063.017-.067.01-.066.011-.063.007H581.3l-.174-.014-.16-.037-.147-.057-.134-.076-.12-.1-.107-.113-.094-.124-.08-.14-.066-.151-.054-.156-.037-.163-.027-.17-.01-.17V339l.017-.17.03-.17h0l.05-.2.06-.186.071-.17.083-.16.087-.15.1-.134.1-.12.11-.11.117-.1.123-.083.127-.073.127-.057.133-.047.133-.03.133-.02.137-.007h.1l.093.01.087.017.08.024.077.027.067.029.068.037.06.037.053.039.05.044.044.043.04.043.033.043.03.043.027.04.02.04h0l.234-1.311h.907l-.754,4.363"
                  fill="none" stroke="#fff" strokeMiterlimit="2.613" strokeWidth="0.216"/>
            <path id="Path_975" data-name="Path 975"
                  d="M581.637,339.786l.093-.007.088-.02.079-.023.078-.037.069-.04.067-.051.063-.056.057-.061.051-.067.047-.073.042-.077.037-.08.033-.084.027-.086.023-.09.017-.09.016-.093.01-.1v-.184l-.01-.087-.013-.084-.023-.08-.027-.077-.033-.066-.04-.064-.043-.054-.05-.046-.057-.04-.06-.027-.063-.017-.07-.007-.09.007-.083.013-.077.027-.077.033-.067.044-.067.053-.057.057-.057.063-.05.073-.047.077-.044.08-.036.087-.033.09-.026.091-.027.1-.02.1-.017.147.007.16.023.163.044.157.066.137.087.11.11.074.134.027Z"
                  fill="#fbb231" fillRule="evenodd"/>
            <path id="Path_976" data-name="Path 976"
                  d="M561.152,339.852l-.134.74h-.84l.022-.137.018-.123.012-.093.013-.05-.046.053-.054.054-.063.05-.07.046-.08.047-.083.04-.088.04-.09.033-.1.03-.1.024-.1.02-.1.013-.1.007h-.1l-.094-.01-.091-.013-.117-.03-.107-.033-.093-.043-.083-.05-.073-.053-.067-.06-.053-.064-.047-.066-.04-.07-.034-.074-.026-.073-.02-.073-.013-.077-.01-.077-.007-.074v-.075l.01-.13.02-.123.033-.117.043-.114.053-.107.06-.1.07-.093.08-.084.083-.077.093-.07.1-.063.1-.054.106-.046.111-.037.113-.03.117-.02h.083l.04-.007h.046l.051-.007.057-.007.057-.006.057-.007h.061l.053-.007h.639l.007-.023.02-.06.016-.07.007-.063-.013-.073-.03-.067-.04-.06-.053-.05-.063-.043-.07-.031-.08-.02-.086-.006h-.4l-.11.007-.108.01-.1.01-.1.014-.1.013-.1.017-.086.016-.08.017-.073.021-.061.02-.046.023-.037.02.237-.8.074-.03.073-.027.081-.023.08-.017.081-.017.086-.013.086-.014.09-.007.093-.006h.754l.093.006.1.017.1.023.108.03.1.04.1.047.1.057.094.063.083.074.073.08.067.09.049.1.035.107.019.113v.127l-.019.133-.338,1.761Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_977" data-name="Path 977"
                  d="M561.152,339.852l-.134.74h-.84l.022-.137.018-.123.012-.093.013-.05h0l-.046.053-.054.054-.063.05-.07.046-.08.047-.083.04-.088.04-.09.033-.1.03-.1.024-.1.02-.1.013-.1.007h-.1l-.094-.01-.091-.013h0l-.117-.03-.107-.033-.093-.043-.083-.05-.073-.053-.067-.06-.053-.064-.047-.066-.04-.07-.034-.074-.026-.073-.02-.073-.013-.077-.01-.077-.007-.074v-.075h0l.01-.13.02-.123.033-.117.043-.114.053-.107.06-.1.07-.093.08-.084.083-.077.093-.07.1-.063.1-.054.106-.046.111-.037.113-.03.117-.02h.083l.04-.007h.046l.051-.007.057-.007.057-.006.057-.007h.061l.053-.007h.639l.007-.023.02-.06.016-.07.007-.063h0l-.013-.073-.03-.067-.04-.06-.053-.05-.063-.043-.07-.031-.08-.02-.086-.006h-.4l-.11.007-.108.01-.1.01-.1.014-.1.013-.1.017-.086.016-.08.017-.073.021-.061.02-.046.023-.037.02.237-.8h0l.074-.03.073-.027.081-.023.08-.017.081-.017.086-.013.086-.014.09-.007.093-.006h.754l.093.006.1.017.1.023.108.03.1.04.1.047.1.057.094.063.083.074.073.08.067.09.049.1.035.107.019.113v.127l-.019.133-.338,1.761"
                  fill="none" stroke="#fff" strokeMiterlimit="2.613" strokeWidth="0.216"/>
            <path id="Path_978" data-name="Path 978"
                  d="M560.348,338.981v-.017l-.013-.016-.02-.01-.033-.006-.036-.006h-.2l-.057.006-.053.006-.054.007-.05.007-.047.01-.04.01-.037.01-.023.007-.027.011-.037.013-.037.017-.043.02-.043.024-.047.023-.043.027-.047.033-.043.033-.041.04-.036.04-.034.043-.023.047-.02.053-.011.053v.114l.016.09.034.067.043.05.05.033.057.02.053.01h.153l.09-.01.087-.014.077-.02.066-.023.064-.03.057-.03.051-.037.043-.033.039-.04.034-.036.027-.04.023-.04.021-.04.016-.037.009-.037.027-.1.023-.11.02-.106.017-.087Z"
                  fill="#ec1c2e" fillRule="evenodd"/>
            <path id="Path_979" data-name="Path 979"
                  d="M554.132,340.593h-.907l.757-4.38h1.577l.151,2.6,1.091-2.6h1.648l-.774,4.38h-.907l.574-3.272h-.036l-1.358,3.272h-1.024l-.007-.137-.023-.364-.03-.517-.034-.594-.034-.6-.03-.523-.023-.38-.007-.16h-.05l-.554,3.272Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_980" data-name="Path 980"
                  d="M554.132,340.593h-.907l.757-4.38h1.577l.151,2.6,1.091-2.6h1.648l-.774,4.38h-.907l.574-3.272h-.036l-1.358,3.272h-1.024l-.007-.137-.023-.364-.03-.517-.034-.594-.034-.6-.03-.523-.023-.38-.007-.16h-.05l-.554,3.272"
                  fill="none" stroke="#fff" strokeMiterlimit="2.613" strokeWidth="0.216"/>
            <path id="Path_981" data-name="Path 981"
                  d="M577.708,339.852l-.137.74h-.837l.006-.137v-.21l.006-.04-.043.05-.051.05-.06.047-.067.047-.07.043-.081.04-.083.04-.083.033-.09.026-.093.027-.093.017-.093.013-.1.007h-.093l-.09-.01-.09-.013-.12-.03-.106-.033-.094-.043-.087-.05-.073-.053-.063-.06-.053-.064-.046-.066-.04-.07-.03-.074-.026-.073-.017-.073-.014-.077-.009-.077-.007-.074v-.074l.007-.13.02-.123.03-.117.044-.114.05-.107.064-.1.07-.093.077-.084.087-.077.09-.07.1-.063.1-.054.1-.046.11-.037.11-.03.113-.02h.081l.044-.007h.046l.05-.007.053-.007.057-.006.06-.007h.057l.057-.007h.655l.007-.023.01-.06.01-.07.006-.063-.013-.073-.026-.067-.043-.06-.051-.05-.06-.043-.07-.031-.073-.02-.08-.006h-.4l-.109.007-.107.01-.107.01-.1.014-.1.013-.1.017-.087.016-.08.017-.074.021-.06.02-.047.023-.036.02.234-.8.073-.03.077-.027.077-.023.08-.017.084-.017.084-.013.086-.014.091-.007.093-.006h.74l.094.006.1.017.1.023.107.03.1.04.1.047.1.057.094.063.084.074.076.08.066.09.051.1.037.107.024.113v.127l-.017.133-.35,1.761Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_982" data-name="Path 982"
                  d="M577.57,340.616l.02-.02.137-.741-.04-.006-.137.741.021-.021v.047h.016V340.6l-.02.02Z" fill="#fff"
                  fillRule="evenodd"/>
            <path id="Path_983" data-name="Path 983"
                  d="M576.715,340.592l.02.024h.837v-.047h-.837l.02.023h-.04v.024h.023l-.02-.024Z" fill="#fff"
                  fillRule="evenodd"/>
            <path id="Path_984" data-name="Path 984"
                  d="M576.768,340.219l-.03-.026-.013.054v.21l-.006.137h.04l.006-.137.007-.12v-.117l-.03-.026.033.026Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_985" data-name="Path 985"
                  d="M575.47,340.646h0l.093.013.09.01h.093l.1-.007.1-.013.093-.017.094-.026.093-.027.084-.033.086-.04.081-.04.069-.047.07-.047.06-.046.054-.054.044-.05-.033-.026-.043.05-.047.047-.06.047-.063.047-.07.04-.08.04-.08.04-.083.033-.087.027-.094.027-.094.017-.089.013-.1.007h-.093l-.09-.01-.087-.013h0l-.006.04Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_986" data-name="Path 986"
                  d="M574.662,339.64h0v.073l.007.074.01.08.013.077.017.073.027.077.03.077.044.07.046.07.054.063.066.064.077.053.087.054.1.043.109.034.12.03.007-.04-.12-.03-.1-.033-.09-.043-.087-.047-.07-.053-.06-.057-.053-.063-.047-.064-.036-.07-.03-.07-.027-.069-.016-.074-.013-.077-.01-.073-.007-.074v-.073h-.047Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_987" data-name="Path 987"
                  d="M575.86,338.355h0l-.115.02-.114.03-.11.037-.106.047-.1.053-.1.067-.09.07-.086.08-.08.083-.07.093-.067.1-.05.11-.043.113-.03.12-.02.127-.01.13h.047v-.13l.02-.12.03-.113.043-.114.05-.1.06-.093.071-.093.073-.083.087-.074.09-.07.093-.06.1-.054.1-.046.11-.037.107-.03.11-.02h0v-.043Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_988" data-name="Path 988"
                  d="M576.547,338.284h-.036l-.047.007h-.1l-.057.006h-.057l-.06.007-.057.006-.053.007-.051.007h-.046l-.044.007h-.079v.04h.079l.044-.007h.046l.051-.007.053-.007.057-.006.061-.007h.057l.057-.007h.183v-.047Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_989" data-name="Path 989"
                  d="M577,338.321l.021-.017h-.1l-.064-.006h-.15l-.08-.007h-.08v.047h.16l.077.007h.234l.02-.017-.02.017h.017v-.02l-.04-.007Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_990" data-name="Path 990"
                  d="M577.031,338.107h0v.063l-.011.067-.01.06-.007.023.04.007.006-.024.01-.06.01-.073.01-.063h-.048Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_991" data-name="Path 991"
                  d="M576.633,337.778h.077l.07.02.067.03.057.04.046.047.04.057.027.06.013.07h.04l-.014-.076-.026-.074-.047-.063-.053-.054-.063-.046-.074-.03-.077-.02-.083-.01v.049Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_992" data-name="Path 992"
                  d="M575.2,337.938l.033.02.033-.017.043-.023.057-.02.073-.02.081-.016.086-.017.1-.017.1-.013.1-.014.107-.01.107-.01.109-.006h.4v-.04h-.094l-.1-.007h-.1l-.107.007-.109.006-.108.01-.107.01-.1.013-.1.013-.1.017-.086.017-.08.017-.073.02-.063.021-.05.023-.04.024.033.02-.04-.006Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_993" data-name="Path 993"
                  d="M575.45,337.117l-.014.017-.233.8.04.007.234-.8-.014.017-.013-.04h-.01v.013l.014-.017Z" fill="#fff"
                  fillRule="evenodd"/>
            <path id="Path_994" data-name="Path 994"
                  d="M576.934,336.961h0l-.113-.007h-.427l-.1.007h-.1l-.093.007-.09.006-.09.014-.083.014-.083.017-.082.017-.079.023-.077.027-.073.03.013.04.074-.03.076-.026.073-.024.081-.016.083-.017.084-.013.083-.014.09-.006.093-.007h.738v-.04Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_995" data-name="Path 995"
                  d="M578.079,338.094h0l.016-.137v-.127l-.023-.117-.037-.11-.053-.1-.067-.094-.08-.083-.083-.074-.1-.064-.1-.06-.108-.047-.1-.04-.11-.03-.1-.023-.1-.016-.094-.007v.04l.094.007.1.017.1.023.1.03.1.04.1.047.1.053.09.063.084.073.073.077.067.086.047.1.037.1.023.11v.127l-.017.13h0l.04.006Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_996" data-name="Path 996" d="M577.728,339.856h0l.351-1.761-.04-.006-.35,1.761h0l.04.006Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_997" data-name="Path 997"
                  d="M576.9,338.982l-.006-.02-.013-.016-.024-.01-.033-.01h-.181l-.054.007-.053.007-.052.007-.052.007-.048.01-.044.006-.044.01-.033.006h-.023l-.027.01-.033.01-.037.013-.043.02-.04.021-.047.023-.043.03-.044.03-.044.037-.036.036-.037.044-.03.047-.023.05-.019.054-.01.057v.114l.016.09.028.067.04.05.05.033.057.02.057.01h.157l.093-.01.087-.017.077-.023.073-.027.063-.03.06-.034.053-.04.047-.037.039-.043.037-.04.03-.044.023-.04.017-.04.013-.04.01-.037.014-.094.017-.1.013-.093.006-.084Z"
                  fill="#fbb231" fillRule="evenodd"/>
            <path id="Path_998" data-name="Path 998"
                  d="M583.772,340.137l.007-.077.026-.09.047-.08.057-.07.07-.056.08-.04.087-.027.093-.01.094.01.086.027.082.04.069.056.057.07.047.08.026.09.007.077h-.1v-.061l-.021-.074-.033-.066-.042-.057-.053-.047-.064-.033-.073-.023-.077-.007-.077.007-.073.023-.063.033-.054.047-.043.057L583.9,340l-.02.074-.007.061Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_999" data-name="Path 999"
                  d="M584.073,340.136v-.2h.224l.036.006.026.01.023.013.017.02.013.02.007.023v.054l-.007.023-.01.02-.007.007h-.1l.02-.006.017-.007.013-.01.007-.011.006-.016v-.043l-.007-.014-.01-.006-.01-.007h-.17v.13Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_1000" data-name="Path 1000"
                  d="M583.773,340.137v.02l.01.1.025.09.047.08.057.067.07.056.081.044.088.026.093.01.094-.01.087-.026.08-.044.069-.056.057-.067.047-.08.026-.09.01-.1v-.02h-.1v.02l-.007.08-.021.074-.033.063-.043.056-.053.047-.063.036-.073.024-.077.007-.076-.007-.073-.024-.063-.036-.054-.047-.043-.056-.033-.063-.02-.074-.007-.08v-.02Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_1001" data-name="Path 1001"
                  d="M584.073,340.137v.2h.084v-.167h.083l.1.167h.067l-.1-.167h.024l.023-.007.02-.007.017-.013.006-.01h-.239Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_1002" data-name="Path 1002"
                  d="M583.771,340.156h0l.01-.1.025-.09.047-.08.057-.07.07-.056.079-.04.088-.027.093-.01h0l.094.01.087.027.08.04.069.056.057.07.047.08.026.09.01.1h0l-.01.1-.026.09-.047.08-.057.067-.069.056-.08.044-.087.026-.094.01h0l-.093-.01-.088-.026-.079-.044-.07-.056-.057-.067-.047-.08-.025-.09-.01-.1m.386.184h-.083v-.4h.224l.036.006.026.01.023.014.017.02.013.02.007.023v.023h0v.03l-.007.024-.01.02-.013.017-.017.013-.02.007-.023.007h-.022l.1.167h-.067l-.1-.167h-.083v.167m.083-.2h0l.03-.007h.024l.02-.006.017-.007.013-.01.006-.011.006-.016v-.024h0v-.017l-.006-.013-.01-.007-.01-.007h-.169v.133h.083m0,.4h0l.077-.007.073-.024.063-.036.053-.047.043-.056.033-.063.021-.074.007-.08h0l-.007-.08-.021-.074-.033-.066-.043-.057-.053-.047-.063-.033-.073-.023-.077-.007h0l-.077.007-.073.023-.062.033-.054.047-.043.057-.033.066-.02.074-.007.08h0l.007.08.02.074.033.063.043.056.054.047.062.036.073.024.077.007"
                  fill="none" stroke="#fff" strokeMiterlimit="2.613" strokeWidth="0.216"/>
            <path id="Path_1003" data-name="Path 1003"
                  d="M577.958,340.592h.941l.336-1.9.013-.073.021-.074.02-.073.027-.073.033-.073.04-.07.044-.067.053-.063.06-.056.067-.054.077-.05.087-.04.1-.033.108-.027.116-.02.13-.007h.083l.151-.857-.094.006-.09.01-.083.016-.08.023-.073.027-.07.034-.067.036-.063.04-.059.047-.057.046-.053.054-.053.053-.051.057-.05.063-.05.06-.046.067.1-.6h-.938l-.624,3.572Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_1004" data-name="Path 1004"
                  d="M577.958,340.592h.941l.336-1.9h0l.013-.073.021-.074.02-.073.027-.073.033-.073.04-.07.044-.067.053-.063.06-.056.067-.054.077-.05.087-.04.1-.033.108-.027.116-.02.13-.007h.083l.151-.857h0l-.094.006-.09.01-.083.016-.08.023-.073.027-.07.034-.067.036-.063.04-.059.047-.057.046-.053.054-.053.053-.051.057-.05.063-.05.06-.046.067.1-.6h-.938l-.624,3.572h0"
                  fill="none" stroke="#fff" strokeMiterlimit="2.613" strokeWidth="0.216"/>
            <path id="Path_1005" data-name="Path 1005"
                  d="M569.125,340.592h.957l.321-1.9.013-.073.016-.07.016-.07.024-.067.026-.067.034-.064.041-.06.047-.057.053-.051.06-.049.074-.04.079-.036.093-.03.1-.026.113-.017.13-.01h.095v-.013l.01-.04.012-.06.017-.074.023-.087.024-.094.03-.093.03-.094.027-.063.033-.064.033-.063.033-.057.03-.05.023-.04.017-.023.007-.01h-.151l-.1.006-.093.01-.087.016-.08.02-.077.026-.07.034-.063.033-.06.04-.056.044-.054.05-.05.05-.05.056-.05.057-.047.064-.05.066-.05.067.12-.6h-.957l-.624,3.572Z"
                  fill="#fff" fillRule="evenodd"/>
            <path id="Path_1006" data-name="Path 1006"
                  d="M569.125,340.592h.957l.321-1.9h0l.013-.073.016-.07.016-.07.024-.067.026-.067.034-.064.041-.06.047-.057.053-.051.06-.049.074-.04.079-.036.093-.03.1-.026.113-.017.13-.01h.095v-.013l.01-.04.012-.06.017-.074.023-.087.024-.094.03-.093.03-.094h0l.027-.063.033-.064.033-.063.033-.057.03-.05.023-.04.017-.023.007-.01h-.151l-.1.006-.093.01-.087.016-.08.02-.077.026-.07.034-.063.033-.06.04-.056.044-.054.05-.05.05-.05.056-.05.057-.047.064-.05.066-.05.067.12-.6h-.957l-.624,3.572h0"
                  fill="none" stroke="#fff" strokeMiterlimit="2.613" strokeWidth="0.216"/>
            <path id="Path_1007" data-name="Path 1007"
                  d="M583.78,344.1v-.013L583.8,344l.047-.077.057-.067.07-.053.081-.04.087-.023.093-.01.094.01.087.023.08.04.069.053.057.067.047.077.026.086v.013h-.1l-.021-.069-.032-.064-.043-.057-.053-.046-.063-.037-.073-.023-.077-.007-.076.007-.073.023-.063.037-.054.046-.043.057-.033.064-.02.069Z"
                  fill="#fbb231" fillRule="evenodd"/>
            <path id="Path_1008" data-name="Path 1008"
                  d="M584.073,344.1v-.112h.224l.036.006.026.006.023.013.017.017.013.02.007.023v.03h-.067v-.034l-.007-.013-.01-.006-.01-.007h-.17v.066Z"
                  fill="#fbb231" fillRule="evenodd"/>
            <path id="Path_1009" data-name="Path 1009" d="M583.771,344.175l.01-.073h.1l-.007.07Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_1010" data-name="Path 1010"
                  d="M584.073,344.175V344.1h.084v.066h.083l.03-.006h.024l.02-.007.017-.006.013-.01.007-.01.006-.016v-.007h.067v.023l-.007.02-.01.017-.013.013Z"
                  fill="#fbb231" fillRule="evenodd"/>
            <path id="Path_1011" data-name="Path 1011" d="M584.61,344.175l-.006-.07h.1l.01.073Z" fill="#fbb231"
                  fillRule="evenodd"/>
            <path id="Path_1012" data-name="Path 1012"
                  d="M583.771,344.175v.01l.01.1.025.09.047.08.057.07.07.057.081.04.087.027.093.01.094-.01.087-.027.08-.04.07-.057.057-.07.046-.08.026-.09.01-.1v-.01h-.1v.01l-.007.08-.021.073-.033.063-.043.056-.053.048-.063.036-.073.023-.077.007-.076-.007-.073-.023-.063-.036-.054-.048-.043-.056-.033-.063-.02-.073-.007-.08v-.01Z"
                  fill="#fbb231" fillRule="evenodd"/>
            <path id="Path_1013" data-name="Path 1013"
                  d="M584.073,344.175v.194h.084V344.2h.083l.1.167h.067l-.1-.167h.024l.023-.006.02-.007.017-.014h-.318Z"
                  fill="#fbb231" fillRule="evenodd"/>
            <path id="Path_1014" data-name="Path 1014"
                  d="M569.23,338.084l-.027-.2-.047-.177-.067-.153-.083-.134-.1-.114-.11-.093-.12-.08-.13-.06-.134-.051-.135-.033-.137-.023-.137-.013h-.13l-.271.014-.24.04-.218.06-.193.084-.167.1-.147.113-.13.127-.106.134-.094.14-.077.147-.06.144-.05.143-.036.141-.027.13-.02.124-.014.107-.017.273.01.247.037.224.056.2.081.177.1.153.113.137.13.113.14.1.151.083.156.064.163.05.167.04.167.024.166.017h.548l.077-.007.07-.006.067-.007.06-.01.057-.01.053-.013.054-.017.05-.016.05-.021.054-.02.053-.027.054-.026.133-.821-.1.057-.094.053-.1.04-.094.036-.09.03-.093.02-.09.017-.09.013-.09.007h-.263l-.086-.007-.087-.01-.083-.011-.087-.013-.067-.013-.067-.024-.066-.029-.062-.037-.058-.04-.054-.05-.05-.05-.047-.057-.039-.057-.036-.063-.027-.061-.023-.062-.013-.064-.007-.064v-.056l.008-.044h2.164l.017-.2.05-.281.024-.254Zm-.9.307H567.11l.009-.047.014-.07.02-.066.023-.067.03-.06.031-.057.036-.054.04-.05.044-.047.043-.04.051-.036.049-.03.057-.026.057-.02.057-.014.06-.01h.064l.06.007.057.01.053.013.053.02.05.027.05.03.044.033.039.04.033.047.034.05.023.058.024.063.012.066.007.074v.08Z"
                  fill="#fff" fillRule="evenodd"/>
        </g>
    </svg>
)

export const PlayIcon = ({className}) => (
    <svg className={className} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M8 7L16 11.3648L8 15.8V7Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
    </svg>
)

export const VisaIcon = ({className}) => (
    <svg id="Visa" xmlns="http://www.w3.org/2000/svg" width="55.123" height="34.451" viewBox="0 0 55.123 34.451"
         className={className}>
        <g id="Rectangle_409" data-name="Rectangle 409" transform="translate(-481.121 -320.884)">
            <rect id="Rectangle_479" data-name="Rectangle 479" width="55.123" height="34.451"
                  transform="translate(481.121 320.884)" fill="none"/>
            <rect id="Rectangle_480" data-name="Rectangle 480" width="52.123" height="31.451"
                  transform="translate(482.621 322.384)" fill="none" stroke="#ededed" strokeWidth="3"/>
        </g>
        <g id="Group_490" data-name="Group 490" transform="translate(-481.121 -320.884)">
            <g id="Group_489" data-name="Group 489">
                <path id="Path_938" data-name="Path 938" d="M506.488,343.285H503.8l1.683-10.343h2.693Z" fill="#0066b2"/>
                <path id="Path_939" data-name="Path 939"
                      d="M501.53,332.942l-2.567,7.114-.3-1.532h0l-.906-4.651a1.153,1.153,0,0,0-1.277-.931h-4.244l-.05.175A10.046,10.046,0,0,1,495,334.3l2.339,8.985h2.806l4.284-10.343Z"
                      fill="#0066b2"/>
                <path id="Path_940" data-name="Path 940"
                      d="M522.71,343.285h2.473l-2.156-10.343h-2.166a1.24,1.24,0,0,0-1.243.77l-4.016,9.572h2.807l.562-1.536h3.422Zm-2.963-3.659,1.414-3.871.8,3.871Z"
                      fill="#0066b2"/>
                <path id="Path_941" data-name="Path 941"
                      d="M515.814,335.428l.385-2.221a7.815,7.815,0,0,0-2.421-.45c-1.338,0-4.51.584-4.51,3.424,0,2.672,3.724,2.7,3.724,4.108s-3.34,1.152-4.443.267l-.4,2.322a7.538,7.538,0,0,0,3.041.584c1.837,0,4.61-.952,4.61-3.541,0-2.689-3.759-2.94-3.759-4.109S514.662,334.793,515.814,335.428Z"
                      fill="#0066b2"/>
            </g>
            <path id="Path_942" data-name="Path 942"
                  d="M498.659,338.524l-.906-4.651a1.153,1.153,0,0,0-1.277-.931h-4.244l-.05.175a10.436,10.436,0,0,1,4,2.006A8.043,8.043,0,0,1,498.659,338.524Z"
                  fill="#f9a533"/>
        </g>
    </svg>
)

export const AmericanExpressIcon = ({className}) => (
    <svg id="American_Express" data-name="American Express" xmlns="http://www.w3.org/2000/svg" width="55.123"
         height="34.45" viewBox="0 0 55.123 34.45" className={className}>
        <g id="Rectangle_413" data-name="Rectangle 413" transform="translate(-601.32 -320.884)">
            <rect id="Rectangle_475" data-name="Rectangle 475" width="55.123" height="34.45"
                  transform="translate(601.32 320.884)" fill="none"/>
            <rect id="Rectangle_476" data-name="Rectangle 476" width="52.123" height="31.45"
                  transform="translate(602.82 322.384)" fill="none" stroke="#ededed" strokeWidth="3"/>
        </g>
        <g id="Group_496" data-name="Group 496" transform="translate(-601.32 -320.884)">
            <path id="Path_1015" data-name="Path 1015"
                  d="M618.422,337.307v-.551l-.264.551h-2.282l-.286-.591v.591h-4.266l-.5-1.193h-.854l-.533,1.193h-2.213v-3.6l1.622-3.669h3.1l.436,1.04v-1.032h3.8l.834,1.75.816-1.758H629.82a2.05,2.05,0,0,1,1.041.283v-.283h3.02v.4a2.458,2.458,0,0,1,1.345-.4h5.328l.469,1.055v-1.055h3.413l.619,1.055v-1.055h3.265v7.277h-3.354l-.787-1.269v1.262h-4.168l-.587-1.3h-.817l-.551,1.3h-2.522a3.261,3.261,0,0,1-1.646-.516v.516h-5.021v-1.7c0-.246-.189-.229-.189-.229h-.183v1.927Z"
                  fill="#fff"/>
            <path id="Path_1016" data-name="Path 1016"
                  d="M615.917,338.883h6.6l.952,1.078.974-1.078h5.074a2.351,2.351,0,0,1,.929.254v-.254h4.518a2.312,2.312,0,0,1,1.056.287v-.287h5.807v.265a1.977,1.977,0,0,1,.969-.265h3.784v.265a2.316,2.316,0,0,1,.98-.265h2.97v6.857a1.629,1.629,0,0,1-1.2.442h-3.916v-.218a2,2,0,0,1-.94.218h-10.4v-1.771c0-.174-.077-.206-.232-.206H633.7v1.978h-3.251v-1.972a2.186,2.186,0,0,1-.963.194h-1.17v1.772h-4.008l-.912-1.112-.974,1.112h-6.5Z"
                  fill="#fff"/>
            <path id="Path_1017" data-name="Path 1017"
                  d="M636.716,339.978h4.141v1.111h-2.878v.968h2.8v.979h-2.8v1.019h2.873v1.083h-4.141Z" fill="#147591"/>
            <path id="Path_1018" data-name="Path 1018"
                  d="M648.857,341.987c1.557.08,1.687.857,1.687,1.687a1.508,1.508,0,0,1-1.524,1.491c-.033,0-.067,0-.1-.005h-2.661v-1.107h2.073c.353,0,.875,0,.875-.5,0-.235-.092-.385-.467-.425-.166-.024-.782-.058-.875-.06-1.406-.037-1.734-.737-1.734-1.588a1.422,1.422,0,0,1,1.339-1.5,1.615,1.615,0,0,1,.171,0H650.3V341.1h-1.948c-.442,0-.92-.053-.92.448,0,.317.235.38.535.41C648.07,341.952,648.769,341.988,648.857,341.987Z"
                  fill="#147591"/>
            <path id="Path_1019" data-name="Path 1019"
                  d="M644.088,341.987c1.557.08,1.687.857,1.687,1.687a1.507,1.507,0,0,1-1.523,1.491c-.034,0-.067,0-.1-.005h-2.66v-1.107h2.073c.351,0,.876,0,.876-.5,0-.235-.093-.385-.467-.425-.167-.024-.783-.058-.875-.06-1.407-.037-1.733-.737-1.733-1.588a1.422,1.422,0,0,1,1.339-1.5,1.6,1.6,0,0,1,.17,0h2.66V341.1h-1.946c-.443,0-.922-.053-.922.448,0,.317.236.38.536.41C643.3,341.952,644,341.988,644.088,341.987Z"
                  fill="#147591"/>
            <path id="Path_1020" data-name="Path 1020"
                  d="M629.068,339.989h-4.445l-1.451,1.6-1.4-1.612h-4.993v5.159h4.831l1.52-1.7,1.486,1.716h2.43V343.4h1.7c.657,0,1.854-.022,1.854-1.831a1.429,1.429,0,0,0-1.265-1.576A1.486,1.486,0,0,0,629.068,339.989Zm-8.125,4.065h-2.919v-1.019h2.793v-.979h-2.793v-.968l3.082.007,1.233,1.4Zm4.843.6-1.82-2.153,1.82-2.015Zm2.856-2.318h-1.578V341.1h1.583a.624.624,0,0,1,.158,1.238.6.6,0,0,1-.158,0Z"
                  fill="#147591"/>
            <path id="Path_1021" data-name="Path 1021"
                  d="M635.187,342.8a1.408,1.408,0,0,0,.876-1.428,1.362,1.362,0,0,0-1.333-1.39,1.486,1.486,0,0,0-.188.009h-3.291v5.159h1.266v-1.82h1.658c.438,0,.61.438.621.875l.035.945h1.233l-.046-1.06C636.016,343.258,635.787,342.867,635.187,342.8Zm-1.094-.534h-1.577V341.1H634.1a.607.607,0,0,1,.693.507.5.5,0,0,1,.007.086c0,.341-.187.573-.706.573Z"
                  fill="#147591"/>
            <rect id="Rectangle_414" data-name="Rectangle 414" width="1.267" height="5.165"
                  transform="translate(631.756 331.071)" fill="#147591"/>
            <path id="Path_1022" data-name="Path 1022"
                  d="M621.388,331.077h4.14v1.111h-2.872v.968h2.792v.978h-2.792v1.019h2.872v1.083h-4.14Z"
                  fill="#147591"/>
            <path id="Path_1023" data-name="Path 1023"
                  d="M630.217,333.886a1.406,1.406,0,0,0,.876-1.428,1.36,1.36,0,0,0-1.331-1.39,1.511,1.511,0,0,0-.19.009h-3.293v5.159h1.267v-1.82H629.2c.438,0,.611.437.622.875l.036.945h1.229l-.046-1.06C631.047,334.347,630.816,333.955,630.217,333.886Zm-1.094-.534h-1.578v-1.169h1.583a.607.607,0,0,1,.693.509.476.476,0,0,1,.007.084c0,.346-.186.576-.7.576Z"
                  fill="#147591"/>
            <path id="Path_1024" data-name="Path 1024"
                  d="M618.465,331.077l-1.554,3.461-1.549-3.461h-1.985v4.969l-2.212-4.969h-1.678l-2.258,5.159h1.336l.495-1.152h2.529l.5,1.152h2.551v-3.83l1.692,3.83h1.152l1.733-3.766v3.766h1.267v-5.159Zm-8.926,2.894.756-1.8.787,1.8Z"
                  fill="#147591"/>
            <path id="Path_1025" data-name="Path 1025"
                  d="M646.095,331.071v3.572l-2.136-3.572h-1.872v4.845l-2.159-4.842h-1.677l-1.745,4s-.738,0-.812-.018c-.306-.062-.783-.278-.789-1.188v-.374c0-1.209.658-1.3,1.5-1.3h.782v-1.123h-1.654c-.576,0-1.829.439-1.871,2.559-.03,1.428.593,2.606,2,2.606h1.671l.495-1.152h2.528l.5,1.152h2.5v-3.778l2.28,3.778h1.728v-5.165Zm-7.781,2.9.754-1.8.794,1.8Z"
                  fill="#147591"/>
        </g>
    </svg>
)

export const AlertIcon = ({className}) => (
    <svg id="alert_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
         className={className}>
        <g id="Alert_icon-2" data-name="Alert icon" transform="translate(-642.814 -142.982)">
            <circle id="Ellipse_56" data-name="Ellipse 56" cx="15.553" cy="15.553" r="15.553"
                    transform="translate(644.762 144.929)" fill="#ededed" stroke="#baa082" strokeMiterlimit="10"
                    strokeWidth="2"/>
            <line id="Line_65" data-name="Line 65" y2="9.396" transform="translate(660.315 152.428)" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
            <circle id="Ellipse_57" data-name="Ellipse 57" cx="2" cy="2" r="2" transform="translate(658.21 165.902)"
                    fill="#baa082"/>
        </g>
    </svg>
)

export const RoomOpenings = ({className}) => (
    <svg id="room_diagram" xmlns="http://www.w3.org/2000/svg" width="113" height="113" viewBox="0 0 113 113"
         className={className}>
        <g id="Room_diagram-2" data-name="Room diagram" transform="translate(-855.747 -191.815)">
            <path id="Path_893" data-name="Path 893" d="M944.252,267.29l9.739-5.513V237.862l-9.739-5.435Z"
                  fill="#baa082"/>
            <line id="Line_94" data-name="Line 94" y2="54.215" transform="translate(912.247 193.315)" fill="none"
                  stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_95" data-name="Line 95" y2="54.353" transform="translate(864.056 221.138)" fill="none"
                  stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_96" data-name="Line 96" y2="54.353" transform="translate(960.437 221.138)" fill="none"
                  stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_97" data-name="Line 97" x2="48.191" y2="27.824" transform="translate(912.247 193.315)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_98" data-name="Line 98" x1="48.191" y2="27.824" transform="translate(864.056 193.315)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <path id="Path_894" data-name="Path 894" d="M886.991,261.651l-13.554,7.825V236.444l13.554-8.1Z"
                  fill="#baa082"/>
            <line id="Line_99" data-name="Line 99" y2="33.312" transform="translate(873.437 236.165)" fill="none"
                  stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_100" data-name="Line 100" y2="33.312" transform="translate(886.991 228.339)" fill="none"
                  stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_101" data-name="Line 101" x1="13.554" y2="7.825" transform="translate(873.437 228.339)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_102" data-name="Line 102" x1="1.926" y2="1.111" transform="translate(877.559 249.238)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_103" data-name="Line 103" x1="48.191" y2="27.824" transform="translate(864.056 247.529)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_104" data-name="Line 104" y1="27.824" x2="48.191" transform="translate(912.247 275.491)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_105" data-name="Line 105" x1="48.191" y1="27.824" transform="translate(864.056 275.491)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <g id="Group_466" data-name="Group 466">
                <path id="Path_895" data-name="Path 895" d="M927.636,232.194l-10.492-6.945,6.557-5.481,3.935,2.286Z"
                      fill="#baa082"/>
                <path id="Path_896" data-name="Path 896" d="M927.637,241.23,916.974,234.7l8.359-4.232,2.3.625Z"
                      fill="#baa082"/>
                <path id="Path_897" data-name="Path 897" d="M927.64,255.741l10.95,7.87v-35.2l-10.95-5.476Z"
                      fill="#baa082"/>
                <path id="Path_898" data-name="Path 898" d="M938.586,262.1" fill="none" stroke="#707070"
                      strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path id="Path_899" data-name="Path 899" d="M938.917,227.858" fill="none" stroke="#707070"
                      strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path id="Path_900" data-name="Path 900" d="M941.9,264.469" fill="none" stroke="#707070"
                      strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path id="Path_901" data-name="Path 901" d="M940.4,229.651" fill="none" stroke="#707070"
                      strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <line id="Line_106" data-name="Line 106" x1="0.026" y2="34.391" transform="translate(927.611 222.054)"
                      fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <line id="Line_107" data-name="Line 107" x2="19.794" y2="10.994" transform="translate(917.884 216.273)"
                      fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path id="Path_902" data-name="Path 902" d="M919.9,251.063" fill="none" stroke="#707070"
                      strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path id="Path_903" data-name="Path 903" d="M926.74,244.851" fill="none" stroke="#707070"
                      strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <line id="Line_108" data-name="Line 108" x1="9.112" y1="5.332" transform="translate(918.524 225.981)"
                      fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <line id="Line_109" data-name="Line 109" x1="6.275" y2="2.761" transform="translate(917.834 220.756)"
                      fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <line id="Line_110" data-name="Line 110" x1="9.751" y1="5.356" transform="translate(917.885 236.356)"
                      fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path id="Path_904" data-name="Path 904" d="M917.954,232.528" fill="none" stroke="#707070"
                      strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                <path id="Path_905" data-name="Path 905"
                      d="M932.482,244.827c0,.846-.5,1.533-1.11,1.533s-1.11-.687-1.11-1.533.5-1.535,1.11-1.535,1.11.688,1.11,1.535"
                      fill="#707070"/>
            </g>
            <line id="Line_111" data-name="Line 111" x1="5.582" y1="3.239" transform="translate(954.856 272.253)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_112" data-name="Line 112" x1="16.64" y1="9.655" transform="translate(927.611 256.445)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_113" data-name="Line 113" x1="5.538" y1="3.213" transform="translate(912.247 247.53)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <path id="Path_906" data-name="Path 906" d="M904.073,234.284l-9.036,5.217v-15.06l9.036-5.217Z"
                  fill="#baa082"/>
            <line id="Line_114" data-name="Line 114" y2="15.06" transform="translate(895.037 224.441)" fill="none"
                  stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_115" data-name="Line 115" y2="15.06" transform="translate(904.073 219.225)" fill="none"
                  stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_116" data-name="Line 116" x1="9.036" y2="5.217" transform="translate(895.037 219.225)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_117" data-name="Line 117" x1="9.036" y2="5.217" transform="translate(895.037 234.284)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_118" data-name="Line 118" x1="8.155" y2="4.707" transform="translate(895.578 228.457)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_119" data-name="Line 119" y2="34.227" transform="translate(944.251 231.873)" fill="none"
                  stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <path id="Path_907" data-name="Path 907" d="M944.252,232.882" fill="none" stroke="#707070"
                  strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_120" data-name="Line 120" x1="8.274" y2="3.641" transform="translate(917.834 230.418)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_121" data-name="Line 121" x1="9.827" y2="4.324" transform="translate(917.784 246.36)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_122" data-name="Line 122" y2="34.1" transform="translate(917.784 216.643)" fill="none"
                  stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_123" data-name="Line 123" y2="33.037" transform="translate(954.856 238.451)" fill="none"
                  stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_124" data-name="Line 124" x2="10.301" y2="5.948" transform="translate(944.555 231.873)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_125" data-name="Line 125" y2="33.91" transform="translate(937.678 227.871)" fill="none"
                  stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_126" data-name="Line 126" x1="9.827" y2="4.324" transform="translate(944.251 261.776)"
                  fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
        </g>
    </svg>
)

export const RoomBuilderArrowUp = ({className, style}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.799" height="65.842" viewBox="0 0 28.799 65.842"
         className={className} style={style}>
        <g id="arrow_1" transform="translate(0.196)">
            <g id="arrow_1-2" data-name="arrow_1" transform="matrix(-0.208, -0.978, 0.978, -0.208, 20.059, 97.562)">
                <path id="Path_16" data-name="Path 16" d="M32.77,5.374c14.918-7.127,35.445-8.938,61.615,5.57"
                      transform="translate(0 0)" fill="none" stroke="#707070" strokeWidth="1"/>
                <path id="Path_17" data-name="Path 17" d="M0,0,5.982,5.352,0,10.767"
                      transform="translate(90.983 3.414) rotate(25)" fill="none" stroke="#707070" strokeWidth="1"/>
            </g>
        </g>
    </svg>
)

export const RoomBuilderArrowRight = ({className, style}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="102.34" height="56.275" viewBox="0 0 102.34 56.275"
         className={className} style={style}>
        <g id="arrow_2" transform="translate(0.483)">
            <g id="arrow_2-2" data-name="arrow_2" transform="translate(0.001 18.395) rotate(-11)">
                <path id="Path_16" data-name="Path 16" d="M0,0S30.243,62.473,94.385,26.914" transform="translate(0 0)"
                      fill="none" stroke="#707070" strokeWidth="1"/>
                <path id="Path_17" data-name="Path 17" d="M0,10.767,5.982,5.415,0,0"
                      transform="translate(86.432 24.686) rotate(-25)" fill="none" stroke="#707070" strokeWidth="1"/>
            </g>
        </g>
    </svg>
)

export const EditIcon = ({className}) => (
    <svg id="edit_icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
         className={className}>
        <g id="Edit_icon-2" data-name="Edit icon" transform="translate(42.646 -158.288)">
            <path id="Path_858" data-name="Path 858" d="M-27.2,161.788l3.056,3.056L-35.2,175.9l-3.947.891.891-3.947Z"
                  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <line id="Line_24" data-name="Line 24" x1="3.056" y1="3.056" transform="translate(-29.155 163.741)"
                  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        </g>
    </svg>
)

export const BillEditIcon = ({className}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" className={className}>
        <g id="bill_edit_icon" transform="translate(1 1)">
            <g id="Edit_icon" data-name="Edit icon" transform="translate(44.082 -156.854)">
                <path id="Path_858" data-name="Path 858" d="M-31.08,161.788l2.064,2.064-7.464,7.464-2.666.6.6-2.666Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_24" data-name="Line 24" x1="2.064" y1="2.064" transform="translate(-33.399 164.107)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <circle id="Ellipse_343" data-name="Ellipse 343" cx="10" cy="10" r="10" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2"/>
        </g>
    </svg>
)

export const BillRemoveIcon = ({className}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" className={className}>
        <g id="bill_remove_icon" transform="translate(1 1)">
            <circle id="Ellipse_345" data-name="Ellipse 345" cx="10" cy="10" r="10" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_386" data-name="Line 386" y1="6.501" x2="7.094" transform="translate(6.453 6.75)" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_387" data-name="Line 387" x1="7.094" y1="6.501" transform="translate(6.453 6.75)" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
        </g>
    </svg>
)

export const GalleryIcon = ({className}) => (
    <svg id="gallery_icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
         className={className}>
        <g id="Gallery_icon-2" data-name="Gallery icon" transform="translate(-221.819 -220.651)">
            <path id="Path_882" data-name="Path 882" d="M240.638,228.4h3.886v12.508H227.113v-3.874" fill="none"
                  stroke="#707070" strokeLinejoin="round" strokeWidth="2"/>
            <rect id="Rectangle_408" data-name="Rectangle 408" width="17.411" height="12.508"
                  transform="translate(223.114 224.398)" fill="none" stroke="#707070" strokeLinejoin="round"
                  strokeWidth="2"/>
            <path id="Path_883" data-name="Path 883"
                  d="M227.647,226.983a1.571,1.571,0,1,0,1.57,1.572v0a1.57,1.57,0,0,0-1.57-1.57Z" fill="#707070"/>
            <path id="Path_884" data-name="Path 884" d="M228.875,234.241h8.753l-4.452-5.687Z" fill="#707070"/>
        </g>
    </svg>
)

export const AddItemIcon = ({className}) => (
    <svg id="add_item_icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
         className={className}>
        <g id="Remove_icon" data-name="Remove icon" transform="translate(-534.075 -216.563)">
            <circle id="Ellipse_58" data-name="Ellipse 58" cx="10" cy="10" r="10" transform="translate(535.076 217.563)"
                    fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_66" data-name="Line 66" x2="9.186" transform="translate(540.483 227.563)" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_127" data-name="Line 127" y2="9.186" transform="translate(545.076 222.97)" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
        </g>
    </svg>
)

export const RemoveItemIcon = ({className}) => (
    <svg id="remove_item_icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
         className={className}>
        <g id="Remove_icon" data-name="Remove icon" transform="translate(-479.442 -216.563)">
            <circle id="Ellipse_58" data-name="Ellipse 58" cx="10" cy="10" r="10" transform="translate(480.443 217.563)"
                    fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2"/>
            <line id="Line_66" data-name="Line 66" x2="9.186" transform="translate(485.85 227.563)" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
        </g>
    </svg>
)

export const InfoBubbleIcon = ({className}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className={className}>
        <g id="info_bubble_icon" transform="translate(0)">
            <g id="Info_bubble" data-name="Info bubble" transform="translate(-473.036 -148.668)">
                <g id="Info_bubble_icon-2" data-name="Info bubble icon">
                    <circle id="Ellipse_33" data-name="Ellipse 33" cx="10" cy="10" r="10"
                            transform="translate(474.036 149.668)" fill="none" stroke="currentColor" strokeMiterlimit="10"
                            strokeWidth="2"/>
                    <g id="Group_461" data-name="Group 461">
                        <path id="Path_865" data-name="Path 865"
                              d="M484.105,161.641a1.838,1.838,0,0,1,.1-.641,2.227,2.227,0,0,1,.276-.524,3.642,3.642,0,0,1,.386-.455c.143-.143.287-.283.435-.421s.291-.3.434-.455a3.2,3.2,0,0,0,.379-.51,2.751,2.751,0,0,0,.269-.614,2.6,2.6,0,0,0,.1-.765,3.033,3.033,0,0,0-.214-1.172,2.409,2.409,0,0,0-.6-.862,2.63,2.63,0,0,0-.917-.538,3.556,3.556,0,0,0-1.179-.186,3.256,3.256,0,0,0-1.3.248,2.739,2.739,0,0,0-.979.7,3.127,3.127,0,0,0-.614,1.055,3.683,3.683,0,0,0-.2,1.3v.011"
                              fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                        <path id="Path_866" data-name="Path 866"
                              d="M484.126,163.884a1.15,1.15,0,1,0,1.15,1.15h0A1.15,1.15,0,0,0,484.126,163.884Z"
                              fill="currentColor"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export const LogoNavBarIcon = ({className}) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 153 21"
        width="153"
        height="21"
    >
        <g id="Group_795" transform="translate(-603.533 -43.543)">
            <rect id="Rectangle_717" width="5.323" height="19.178" transform="translate(627.744 44.138)"
                  fill="#0d5261"/>
            <path id="Path_1343" d="M615.027,58.9l-5.211-14.762h-5.67l7.244,19.178h7.278l7.145-19.178h-5.334Z"
                  fill="#0d5261"/>
            <path id="Path_1344"
                  d="M651.244,54.929a4.965,4.965,0,0,0-1.526-1.391,4.672,4.672,0,0,0,1.3-1.445,4.847,4.847,0,0,0,.648-2.46,4.618,4.618,0,0,0-2.2-4.138,10.69,10.69,0,0,0-5.765-1.357h-7.633V63.316h8.182a10.929,10.929,0,0,0,4.1-.7,6.163,6.163,0,0,0,2.723-2.015,4.991,4.991,0,0,0,.969-3.038,4.411,4.411,0,0,0-.8-2.634Zm-10.115-6.655h2.655a4.111,4.111,0,0,1,2.147.428,1.29,1.29,0,0,1,.589,1.169,1.379,1.379,0,0,1-.652,1.279h0a4.734,4.734,0,0,1-2.361.446h-2.381Zm5.124,10.45a3.894,3.894,0,0,1-2.057.456H641.13l0-3.43H644.2a4.133,4.133,0,0,1,2.146.414,1.355,1.355,0,0,1,.563,1.236A1.437,1.437,0,0,1,646.253,58.724Z"
                  fill="#0d5261"/>
            <path id="Path_1345"
                  d="M708.762,56.388a6.351,6.351,0,0,0,1.737-4.613,6.8,6.8,0,0,0-2.528-5.61q-2.534-2.03-7.59-2.031H692.36V63.312h5.508v-4.17h3.441a3.156,3.156,0,0,1,.821.094c2.251.609,2.175,3.219,2.175,4.072h5.163c.075-2.333-.269-4.434-1.911-5.919a7.809,7.809,0,0,0,1.2-1Zm-5.017-2.359a7.055,7.055,0,0,1-3.717.74h-2.169l0-6.058h2.2a7.388,7.388,0,0,1,3.545.706,2.421,2.421,0,0,1,1.273,2.289A2.574,2.574,0,0,1,703.745,54.029Z"
                  fill="#0d5261"/>
            <path id="Path_1346"
                  d="M749.472,44.793l-4.225,5.784-4.453-5.784h-6.807l8.11,10.432v7.933h5.968V55.076l7.857-10.284Z"
                  fill="#0d5261"/>
            <path id="Path_1347"
                  d="M728.123,44.793l-.7,1.541-.175-.143a8.64,8.64,0,0,0-5.57-1.958c-5.637,0-9.575,3.982-9.575,9.683a9.346,9.346,0,0,0,8.931,9.745q.321.015.644.006a8.627,8.627,0,0,0,5.5-1.9l.178-.145.593,1.421h4.663V44.793Zm-5.4,13.548c-.064,0-.128,0-.193,0a4.405,4.405,0,1,1,4.333-4.476v.064A4.277,4.277,0,0,1,722.727,58.341Z"
                  fill="#0d5261"/>
            <path id="Path_1348" d="M674.681,49.641h4.942V63.312h5.508V49.641h4.944V44.134H674.681Z" fill="#0d5261"/>
            <path id="Path_1349"
                  d="M663.795,44.04a9.711,9.711,0,0,0-10.075,9.333q-.012.334,0,.667c0,5.644,4.015,10,10.493,10a9.605,9.605,0,0,0,8.257-3.9l-4.545-2.614a4.946,4.946,0,0,1-3.637,1.439c-2.083,0-3.826-.682-4.544-2.651H673.2a11.027,11.027,0,0,0,.223-2.27l0,0C673.416,48.32,669.325,44.04,663.795,44.04Zm-4.167,8.03a4.266,4.266,0,0,1,8.143,0Z"
                  fill="#0d5261"/>
        </g>
    </svg>
)

export const SearchIcon = ({className}) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <g id="Group_603" transform="translate(-111.73 -221.82)">
            <g id="Ellipse_1">
                <ellipse id="Ellipse_93" cx="8.028" cy="7.75" rx="8.028" ry="7.75" transform="translate(114.73 224.821)"
                         fill="none"/>
                <ellipse id="Ellipse_94" cx="7.028" cy="6.75" rx="7.028" ry="6.75" transform="translate(115.73 225.821)"
                         fill="none" stroke="#707070" strokeWidth="2"/>
            </g>
            <line id="Line_1" x2="5.175" y2="5.319" transform="translate(127.556 237.502)" fill="none" stroke="#707070"
                  strokeWidth="2"/>
        </g>
        <rect id="Rectangle_500" width="24" height="24" fill="none"/>
    </svg>
)

export const ShopItIcon = ({className}) => (
    <svg id="shop_it_icon" xmlns="http://www.w3.org/2000/svg" width="155" height="155" viewBox="0 0 155 155"
         className={className}>
        <rect id="Rectangle_669" data-name="Rectangle 669" width="155" height="155" fill="none"/>
        <g id="Group_757" data-name="Group 757" transform="translate(-272.133 -608.199)">
            <g id="Group_746" data-name="Group 746">
                <path id="Path_1250" data-name="Path 1250"
                      d="M336.757,679.808v-5.786c0-5.651,4.088-10.232,9.13-10.232h32.839c5.043,0,9.131,4.581,9.131,10.232v5.784"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1251" data-name="Path 1251"
                      d="M334.193,689c-.16.015-.321.023-.484.023a5.113,5.113,0,1,1,4.733-3.186" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1252" data-name="Path 1252" d="M386.066,685.559a5.11,5.11,0,1,1,4.839,3.466" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1253" data-name="Path 1253" d="M381.707,702.134h-2.851l-2.044-5.557h6.939Z" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1254" data-name="Path 1254" d="M345.757,702.134h-2.851l-2.044-5.557H347.8Z" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_13" data-name="Path 13" d="M347.8,672.889l2.847,2.1-2.847,2.129" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_13-2" data-name="Path 13" d="M354.731,672.889l-2.846,2.1,2.846,2.129" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_13-3" data-name="Path 13" d="M369.882,672.889l2.847,2.1-2.847,2.129" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_13-4" data-name="Path 13" d="M376.812,672.889l-2.847,2.1,2.847,2.129" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <rect id="Rectangle_670" data-name="Rectangle 670" width="57.196" height="10.739" rx="5.369"
                      transform="translate(333.709 685.838)" fill="none" stroke="#007d7d" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <g id="Group_747" data-name="Group 747">
                <rect id="Rectangle_671" data-name="Rectangle 671" width="19.229" height="25.237"
                      transform="translate(338.988 628.407)" fill="none" stroke="#007d7d" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
                <ellipse id="Ellipse_208" data-name="Ellipse 208" cx="3.277" cy="3.281" rx="3.277" ry="3.281"
                         transform="translate(346.934 632.893)" fill="#007d7d"/>
                <path id="Path_1255" data-name="Path 1255"
                      d="M338.988,642.287s3.96-1.824,10.443,4.47c2.663,2.585,6.052,1.777,8.788,1.777" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <rect id="Rectangle_672" data-name="Rectangle 672" width="19.229" height="25.237"
                      transform="translate(385.625 653.644) rotate(-180)" fill="none" stroke="#007d7d"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1256" data-name="Path 1256"
                      d="M385.625,634.317s-7.847-.424-9.249,7.725c-1.107,6.43-7.245,6.492-9.981,6.492" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_209" data-name="Ellipse 209" cx="1.638" cy="1.638" r="1.638"
                        transform="translate(378.82 646.575)" fill="#007d7d"/>
            </g>
            <g id="Group_748" data-name="Group 748">
                <path id="Path_1257" data-name="Path 1257"
                      d="M298.174,729.394v-4.336A1.694,1.694,0,0,1,299.8,723.3h19.51a1.694,1.694,0,0,1,1.627,1.754v4.336"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <line id="Line_261" data-name="Line 261" x1="22.764" transform="translate(298.174 737.348)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <line id="Line_262" data-name="Line 262" x2="19.51" transform="translate(299.801 732.749)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <rect id="Rectangle_673" data-name="Rectangle 673" width="4.87" height="7.954" rx="1.2"
                      transform="translate(294.931 729.394)" fill="none" stroke="#007d7d" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1"/>
                <rect id="Rectangle_674" data-name="Rectangle 674" width="4.87" height="7.954" rx="1.2"
                      transform="translate(319.311 729.394)" fill="none" stroke="#007d7d" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1"/>
                <line id="Line_263" data-name="Line 263" y2="14.044" transform="translate(309.556 723.304)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_1258" data-name="Path 1258" d="M299.584,740.387h-1.41v-3.039h2.82Z" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_1259" data-name="Path 1259" d="M319.528,740.387h1.41v-3.039h-2.82Z" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <ellipse id="Ellipse_210" data-name="Ellipse 210" cx="0.61" cy="0.657" rx="0.61" ry="0.657"
                         transform="translate(301.275 727.451)" fill="#007d7d"/>
                <ellipse id="Ellipse_211" data-name="Ellipse 211" cx="0.61" cy="0.657" rx="0.61" ry="0.657"
                         transform="translate(305.111 727.451)" fill="#007d7d"/>
                <ellipse id="Ellipse_212" data-name="Ellipse 212" cx="0.61" cy="0.657" rx="0.61" ry="0.657"
                         transform="translate(312.876 727.451)" fill="#007d7d"/>
                <ellipse id="Ellipse_213" data-name="Ellipse 213" cx="0.61" cy="0.657" rx="0.61" ry="0.657"
                         transform="translate(316.687 727.451)" fill="#007d7d"/>
            </g>
            <g id="Group_751" data-name="Group 751">
                <path id="Path_1260" data-name="Path 1260"
                      d="M378.985,734.951h-1.709a1.534,1.534,0,0,1-1.534-1.534v-1.765a1.534,1.534,0,0,1,1.534-1.534h1.8a1.534,1.534,0,0,1,1.534,1.534v1.765a1.522,1.522,0,0,1-.2.764"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_1261" data-name="Path 1261"
                      d="M401.749,734.951h1.709a1.534,1.534,0,0,0,1.534-1.534v-1.765a1.534,1.534,0,0,0-1.534-1.534h-1.8a1.534,1.534,0,0,0-1.534,1.534v1.765a1.523,1.523,0,0,0,.2.764"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_1262" data-name="Path 1262"
                      d="M401.749,730.118v-5.06a1.694,1.694,0,0,0-1.627-1.754h-19.51a1.694,1.694,0,0,0-1.627,1.754v5.06"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <g id="Group_749" data-name="Group 749">
                    <line id="Line_264" data-name="Line 264" x1="1.099" y2="1.1" transform="translate(396.555 726.873)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                    <line id="Line_265" data-name="Line 265" x1="1.099" y2="1.1" transform="translate(393.75 729.681)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                    <line id="Line_266" data-name="Line 266" x2="1.099" y2="1.1" transform="translate(393.75 726.873)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                    <line id="Line_267" data-name="Line 267" x2="1.099" y2="1.1" transform="translate(396.555 729.681)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                    <circle id="Ellipse_214" data-name="Ellipse 214" cx="1.207" cy="1.207" r="1.207"
                            transform="translate(394.495 727.62)" fill="none" stroke="#007d7d" strokeLinecap="round"
                            strokeLinejoin="round" strokeWidth="1"/>
                </g>
                <g id="Group_750" data-name="Group 750">
                    <line id="Line_268" data-name="Line 268" x1="1.099" y2="1.1" transform="translate(385.909 726.873)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                    <line id="Line_269" data-name="Line 269" x1="1.099" y2="1.1" transform="translate(383.104 729.681)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                    <line id="Line_270" data-name="Line 270" x2="1.099" y2="1.1" transform="translate(385.909 729.681)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                    <line id="Line_271" data-name="Line 271" x2="1.099" y2="1.1" transform="translate(383.104 726.873)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                    <circle id="Ellipse_215" data-name="Ellipse 215" cx="1.207" cy="1.207" r="1.207"
                            transform="translate(383.849 727.62)" fill="none" stroke="#007d7d" strokeLinecap="round"
                            strokeLinejoin="round" strokeWidth="1"/>
                </g>
                <path id="Path_1263" data-name="Path 1263"
                      d="M401.749,734.18H378.985v1.854a2.287,2.287,0,0,0,2.438,2.1h17.888a2.287,2.287,0,0,0,2.438-2.1V734.18Z"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <rect id="Rectangle_675" data-name="Rectangle 675" width="17.889" height="2.258"
                      transform="translate(381.422 738.13)" fill="none" stroke="#007d7d" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1"/>
            </g>
            <g id="Group_752" data-name="Group 752">
                <path id="Path_1264" data-name="Path 1264"
                      d="M339.034,730.441v-2.578c0-2.518,1.773-4.559,3.961-4.559h14.248c2.188,0,3.962,2.041,3.962,4.559v2.577"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_1265" data-name="Path 1265"
                      d="M337.921,734.537a2.074,2.074,0,0,1-.21.01,2.28,2.28,0,1,1,2.217-2.279,2.337,2.337,0,0,1-.163.86"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_1266" data-name="Path 1266"
                      d="M360.437,733.03a2.338,2.338,0,0,1-.127-.762,2.218,2.218,0,1,1,2.217,2.279" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_1267" data-name="Path 1267" d="M358.536,740.387H357.3l-.887-2.476h3.011Z" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_1268" data-name="Path 1268" d="M342.939,740.387H341.7l-.887-2.476h3.011Z" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_13-5" data-name="Path 13" d="M343.478,727.358l1.235.937-1.235.949" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_13-6" data-name="Path 13" d="M346.832,727.358l-1.235.937,1.235.949" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_13-7" data-name="Path 13" d="M353.406,727.358l1.235.937-1.235.949" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_13-8" data-name="Path 13" d="M356.76,727.358l-1.235.937,1.235.949" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <rect id="Rectangle_676" data-name="Rectangle 676" width="24.816" height="4.784" rx="2.392"
                      transform="translate(337.711 733.127)" fill="none" stroke="#007d7d" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1"/>
            </g>
            <path id="Path_1269" data-name="Path 1269" d="M287.682,726.834a74.909,74.909,0,1,1,124.612-1.062"
                  fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_753" data-name="Group 753">
                <rect id="Rectangle_677" data-name="Rectangle 677" width="16.589" height="20.979" rx="4.586"
                      transform="translate(302.715 681.155)" fill="none" stroke="#007d7d" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1270" data-name="Path 1270" d="M316.9,667.815H305.122l1.937-9.159h7.9Z" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <ellipse id="Ellipse_216" data-name="Ellipse 216" cx="4.814" cy="4.82" rx="4.814" ry="4.82"
                         transform="translate(306.195 671.515)" fill="none" stroke="#007d7d" strokeLinecap="round"
                         strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_272" data-name="Line 272" y2="3.7" transform="translate(311.009 667.815)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_273" data-name="Line 273" x2="16.589" transform="translate(302.715 695.086)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_274" data-name="Line 274" x2="16.589" transform="translate(302.715 688.284)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <g id="Group_754" data-name="Group 754">
                <path id="Path_1271" data-name="Path 1271"
                      d="M306.648,754.22a.826.826,0,0,1,.18-.493.513.513,0,0,1,.413-.247.7.7,0,0,1,.4.227,4.313,4.313,0,0,0,.688.486,2.263,2.263,0,0,0,1.015.3v-3.049c-.261-.094-.46-.171-.6-.227s-.323-.138-.552-.246a2.116,2.116,0,0,1-.532-.337,4.911,4.911,0,0,1-.4-.422,1.492,1.492,0,0,1-.3-.577,2.753,2.753,0,0,1-.091-.734,2.1,2.1,0,0,1,.708-1.714,3.254,3.254,0,0,1,1.76-.7v-.377a.264.264,0,0,1,.11-.208.407.407,0,0,1,.266-.091.443.443,0,0,1,.266.091.259.259,0,0,1,.123.208v.351a4.758,4.758,0,0,1,1.461.267q.669.24.669.669a.936.936,0,0,1-.157.488.476.476,0,0,1-.42.253,2.188,2.188,0,0,1-.568-.221,2.766,2.766,0,0,0-.985-.26v2.634a8.757,8.757,0,0,1,.859.37,4.208,4.208,0,0,1,.723.487,1.948,1.948,0,0,1,.575.785,2.916,2.916,0,0,1,.193,1.109,2.62,2.62,0,0,1-.632,1.847,2.8,2.8,0,0,1-1.718.841v.434a.251.251,0,0,1-.123.2.442.442,0,0,1-.266.09.411.411,0,0,1-.266-.089.259.259,0,0,1-.11-.205v-.4a3.547,3.547,0,0,1-1.916-.518Q306.647,754.744,306.648,754.22Zm1.518-5.449a.956.956,0,0,0,.316.772,3.194,3.194,0,0,0,.944.5v-2.363Q308.168,747.835,308.166,748.771Zm1.87,5.7a1.211,1.211,0,0,0,1.143-1.284,1.27,1.27,0,0,0-.3-.9,2.418,2.418,0,0,0-.844-.558Z"
                      fill="#007d7d"/>
            </g>
            <g id="Group_755" data-name="Group 755">
                <path id="Path_1272" data-name="Path 1272"
                      d="M343.735,754.22a.827.827,0,0,1,.18-.493.513.513,0,0,1,.413-.247.7.7,0,0,1,.4.227,4.361,4.361,0,0,0,.688.486,2.267,2.267,0,0,0,1.015.3v-3.049c-.261-.094-.46-.171-.6-.227s-.323-.138-.552-.246a2.135,2.135,0,0,1-.532-.337,4.911,4.911,0,0,1-.4-.422,1.492,1.492,0,0,1-.3-.577,2.752,2.752,0,0,1-.091-.734,2.1,2.1,0,0,1,.708-1.714,3.257,3.257,0,0,1,1.76-.7v-.377a.264.264,0,0,1,.11-.208.407.407,0,0,1,.266-.091.448.448,0,0,1,.267.091.259.259,0,0,1,.123.208v.351a4.769,4.769,0,0,1,1.461.267q.668.24.668.669a.936.936,0,0,1-.157.488.476.476,0,0,1-.42.253,2.188,2.188,0,0,1-.568-.221,2.761,2.761,0,0,0-.984-.26v2.634a8.728,8.728,0,0,1,.858.37,4.251,4.251,0,0,1,.724.487,1.955,1.955,0,0,1,.574.785,2.916,2.916,0,0,1,.193,1.109,2.616,2.616,0,0,1-.632,1.847,2.8,2.8,0,0,1-1.717.841v.434a.251.251,0,0,1-.123.2.447.447,0,0,1-.267.09.411.411,0,0,1-.266-.089.259.259,0,0,1-.11-.205v-.4a3.548,3.548,0,0,1-1.916-.518Q343.734,754.744,343.735,754.22Zm1.519-5.449a.955.955,0,0,0,.315.772,3.193,3.193,0,0,0,.944.5v-2.363Q345.255,747.835,345.254,748.771Zm1.869,5.7a1.211,1.211,0,0,0,1.144-1.284,1.271,1.271,0,0,0-.3-.9,2.418,2.418,0,0,0-.844-.558Z"
                      fill="#baa082"/>
                <path id="Path_1273" data-name="Path 1273"
                      d="M350.422,754.22a.828.828,0,0,1,.181-.493.511.511,0,0,1,.412-.247.7.7,0,0,1,.4.227,4.328,4.328,0,0,0,.689.486,2.259,2.259,0,0,0,1.014.3v-3.049c-.26-.094-.459-.171-.6-.227s-.323-.138-.552-.246a2.109,2.109,0,0,1-.531-.337,4.762,4.762,0,0,1-.4-.422,1.494,1.494,0,0,1-.3-.577,2.793,2.793,0,0,1-.091-.734,2.093,2.093,0,0,1,.709-1.714,3.254,3.254,0,0,1,1.759-.7v-.377a.264.264,0,0,1,.11-.208.409.409,0,0,1,.267-.091.448.448,0,0,1,.266.091.259.259,0,0,1,.123.208v.351a4.764,4.764,0,0,1,1.461.267q.669.24.668.669a.936.936,0,0,1-.157.488.475.475,0,0,1-.419.253,2.175,2.175,0,0,1-.569-.221,2.761,2.761,0,0,0-.984-.26v2.634a8.728,8.728,0,0,1,.858.37,4.251,4.251,0,0,1,.724.487,1.936,1.936,0,0,1,.574.785,2.915,2.915,0,0,1,.194,1.109,2.62,2.62,0,0,1-.632,1.847,2.8,2.8,0,0,1-1.718.841v.434a.251.251,0,0,1-.123.2.447.447,0,0,1-.266.09.414.414,0,0,1-.267-.089.259.259,0,0,1-.11-.205v-.4a3.547,3.547,0,0,1-1.916-.518Q350.422,754.744,350.422,754.22Zm1.519-5.449a.955.955,0,0,0,.315.772,3.219,3.219,0,0,0,.944.5v-2.363Q351.941,747.835,351.941,748.771Zm1.87,5.7a1.211,1.211,0,0,0,1.143-1.284,1.27,1.27,0,0,0-.3-.9,2.418,2.418,0,0,0-.844-.558Z"
                      fill="#baa082"/>
            </g>
            <g id="Group_756" data-name="Group 756">
                <path id="Path_1274" data-name="Path 1274"
                      d="M380.771,754.22a.828.828,0,0,1,.181-.493.512.512,0,0,1,.412-.247.7.7,0,0,1,.4.227,4.328,4.328,0,0,0,.689.486,2.259,2.259,0,0,0,1.014.3v-3.049c-.26-.094-.459-.171-.6-.227s-.323-.138-.552-.246a2.109,2.109,0,0,1-.531-.337,4.762,4.762,0,0,1-.4-.422,1.494,1.494,0,0,1-.3-.577A2.793,2.793,0,0,1,381,748.9a2.1,2.1,0,0,1,.709-1.714,3.254,3.254,0,0,1,1.759-.7v-.377a.264.264,0,0,1,.11-.208.409.409,0,0,1,.267-.091.448.448,0,0,1,.266.091.259.259,0,0,1,.123.208v.351a4.764,4.764,0,0,1,1.461.267q.669.24.668.669a.927.927,0,0,1-.157.488.475.475,0,0,1-.419.253,2.175,2.175,0,0,1-.569-.221,2.76,2.76,0,0,0-.984-.26v2.634a8.728,8.728,0,0,1,.858.37,4.212,4.212,0,0,1,.724.487,1.948,1.948,0,0,1,.575.785,2.935,2.935,0,0,1,.193,1.109,2.62,2.62,0,0,1-.632,1.847,2.8,2.8,0,0,1-1.718.841v.434a.251.251,0,0,1-.123.2.447.447,0,0,1-.266.09.414.414,0,0,1-.267-.089.259.259,0,0,1-.11-.205v-.4a3.541,3.541,0,0,1-1.915-.518Q380.771,754.744,380.771,754.22Zm1.519-5.449a.955.955,0,0,0,.315.772,3.219,3.219,0,0,0,.944.5v-2.363Q382.29,747.835,382.29,748.771Zm1.87,5.7a1.211,1.211,0,0,0,1.143-1.284,1.27,1.27,0,0,0-.3-.9,2.418,2.418,0,0,0-.844-.558Z"
                      fill="#007d7d"/>
                <path id="Path_1275" data-name="Path 1275"
                      d="M387.459,754.22a.826.826,0,0,1,.18-.493.513.513,0,0,1,.413-.247.7.7,0,0,1,.4.227,4.268,4.268,0,0,0,.688.486,2.263,2.263,0,0,0,1.015.3v-3.049c-.261-.094-.46-.171-.6-.227s-.323-.138-.552-.246a2.116,2.116,0,0,1-.532-.337,4.758,4.758,0,0,1-.4-.422,1.478,1.478,0,0,1-.3-.577,2.752,2.752,0,0,1-.091-.734,2.1,2.1,0,0,1,.708-1.714,3.254,3.254,0,0,1,1.76-.7v-.377a.265.265,0,0,1,.11-.208.407.407,0,0,1,.266-.091.446.446,0,0,1,.266.091.259.259,0,0,1,.123.208v.351a4.758,4.758,0,0,1,1.461.267q.669.24.669.669a.936.936,0,0,1-.157.488.476.476,0,0,1-.42.253,2.2,2.2,0,0,1-.569-.221,2.755,2.755,0,0,0-.984-.26v2.634a8.758,8.758,0,0,1,.859.37,4.207,4.207,0,0,1,.723.487,1.948,1.948,0,0,1,.575.785,2.916,2.916,0,0,1,.193,1.109,2.62,2.62,0,0,1-.632,1.847,2.8,2.8,0,0,1-1.718.841v.434a.251.251,0,0,1-.123.2.444.444,0,0,1-.266.09.411.411,0,0,1-.266-.089.259.259,0,0,1-.11-.205v-.4a3.548,3.548,0,0,1-1.916-.518Q387.458,754.744,387.459,754.22Zm1.518-5.449a.959.959,0,0,0,.315.772,3.214,3.214,0,0,0,.945.5v-2.363Q388.979,747.835,388.977,748.771Zm1.87,5.7a1.211,1.211,0,0,0,1.143-1.284,1.27,1.27,0,0,0-.3-.9,2.418,2.418,0,0,0-.844-.558Z"
                      fill="#007d7d"/>
                <path id="Path_1276" data-name="Path 1276"
                      d="M394.146,754.22a.833.833,0,0,1,.18-.493.513.513,0,0,1,.413-.247.7.7,0,0,1,.4.227,4.36,4.36,0,0,0,.688.486,2.267,2.267,0,0,0,1.015.3v-3.049c-.261-.094-.46-.171-.6-.227s-.323-.138-.552-.246a2.135,2.135,0,0,1-.532-.337,4.906,4.906,0,0,1-.4-.422,1.492,1.492,0,0,1-.3-.577,2.752,2.752,0,0,1-.091-.734,2.1,2.1,0,0,1,.708-1.714,3.257,3.257,0,0,1,1.76-.7v-.377a.264.264,0,0,1,.11-.208.407.407,0,0,1,.266-.091.448.448,0,0,1,.267.091.259.259,0,0,1,.123.208v.351a4.769,4.769,0,0,1,1.461.267q.668.24.668.669a.936.936,0,0,1-.157.488.476.476,0,0,1-.42.253,2.188,2.188,0,0,1-.568-.221,2.76,2.76,0,0,0-.984-.26v2.634a8.728,8.728,0,0,1,.858.37,4.251,4.251,0,0,1,.724.487,1.955,1.955,0,0,1,.574.785,2.916,2.916,0,0,1,.193,1.109,2.616,2.616,0,0,1-.632,1.847,2.8,2.8,0,0,1-1.717.841v.434a.251.251,0,0,1-.123.2.447.447,0,0,1-.267.09.411.411,0,0,1-.266-.089.259.259,0,0,1-.11-.205v-.4a3.547,3.547,0,0,1-1.916-.518Q394.145,754.744,394.146,754.22Zm1.519-5.449a.955.955,0,0,0,.315.772,3.206,3.206,0,0,0,.944.5v-2.363Q395.665,747.835,395.665,748.771Zm1.87,5.7a1.211,1.211,0,0,0,1.143-1.284,1.271,1.271,0,0,0-.3-.9,2.422,2.422,0,0,0-.843-.558Z"
                      fill="#007d7d"/>
            </g>
            <rect id="Rectangle_678" data-name="Rectangle 678" width="35.957" height="24.001"
                  transform="translate(332.141 719.49)" fill="none" stroke="#baa082" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1277" data-name="Path 1277"
                  d="M356.562,713.727l5.613-6.462,5.612,6.462h44.5v48.017H287.683V713.727Z" fill="none" stroke="#007d7d"
                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        </g>
    </svg>
)

export const SpotItIcon = ({className}) => (
    <svg id="spot_it_icon" xmlns="http://www.w3.org/2000/svg" width="155" height="155" viewBox="0 0 155 155"
         className={className}>
        <defs>
            <clipPath id="clip-path">
                <circle id="Ellipse_234" data-name="Ellipse 234" cx="74.25" cy="74.25" r="74.25"
                        transform="translate(65.751 617.886)" fill="none"/>
            </clipPath>
        </defs>
        <g id="Group_794" data-name="Group 794" transform="translate(-61.225 -614.637)">
            <path id="Path_1322" data-name="Path 1322" d="M77.573,650.555a75.053,75.053,0,0,1,97.852-24.543" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1323" data-name="Path 1323" d="M203.1,651.576A75.013,75.013,0,1,1,70.964,721.493" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_787" data-name="Group 787">
                <g id="Group_786" data-name="Group 786" clipPath="url(#clip-path)">
                    <g id="Group_785" data-name="Group 785">
                        <g id="Group_782" data-name="Group 782">
                            <path id="Path_1324" data-name="Path 1324"
                                  d="M151.306,708.439h32.409a3.225,3.225,0,0,1,3.225,3.224v7.976" fill="none"
                                  stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                            <line id="Line_290" data-name="Line 290" x1="45.113" transform="translate(141.826 734.269)"
                                  fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="1.5"/>
                            <line id="Line_291" data-name="Line 291" x2="38.665" transform="translate(145.051 725.809)"
                                  fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="1.5"/>
                            <path id="Path_1325" data-name="Path 1325"
                                  d="M145.051,721.639v10.63a2,2,0,0,1-2,2H137.4a2,2,0,0,1-2-2v-10.63" fill="none"
                                  stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                            <rect id="Rectangle_698" data-name="Rectangle 698" width="9.652" height="14.629" rx="2"
                                  transform="translate(183.715 719.639)" fill="none" stroke="#007d7d"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                            <line id="Line_292" data-name="Line 292" y2="25.83" transform="translate(164.383 708.439)"
                                  fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="1.5"/>
                            <path id="Path_1326" data-name="Path 1326" d="M144.621,739.858h-2.795v-5.589h5.589Z"
                                  fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="1.5"/>
                            <path id="Path_1327" data-name="Path 1327" d="M184.145,739.858h2.795v-5.589H181.35Z"
                                  fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="1.5"/>
                            <circle id="Ellipse_231" data-name="Ellipse 231" cx="1.209" cy="1.209" r="1.209"
                                    transform="translate(170.964 716.065)" fill="#007d7d"/>
                            <circle id="Ellipse_232" data-name="Ellipse 232" cx="1.209" cy="1.209" r="1.209"
                                    transform="translate(178.516 716.065)" fill="#007d7d"/>
                            <circle id="Ellipse_233" data-name="Ellipse 233" cx="1.209" cy="1.209" r="1.209"
                                    transform="translate(155.524 716.065)" fill="#007d7d"/>
                        </g>
                        <g id="Group_783" data-name="Group 783">
                            <path id="Path_1328" data-name="Path 1328" d="M210.2,699.566H195.518l2.415-11.405h9.85Z"
                                  fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="1.5"/>
                            <line id="Line_293" data-name="Line 293" y2="36.477" transform="translate(202.858 699.566)"
                                  fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="1.5"/>
                        </g>
                        <g id="Group_784" data-name="Group 784">
                            <path id="Path_1329" data-name="Path 1329" d="M151.306,693.839h33.032v4.521H151.306"
                                  fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="1.5"/>
                            <rect id="Rectangle_699" data-name="Rectangle 699" width="10.781" height="4.067"
                                  transform="translate(171.702 689.683)" fill="none" stroke="#007d7d"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                            <rect id="Rectangle_700" data-name="Rectangle 700" width="10.781" height="4.067"
                                  transform="translate(169.326 685.528)" fill="none" stroke="#007d7d"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                            <path id="Path_1330" data-name="Path 1330"
                                  d="M161.821,693.878H156.9c0-2.476-2.479-7.051-.414-8.8h0l-1.07-3.334h7.828l-1.009,3.334h0c2.064,1.75-.414,6.325-.414,8.8Z"
                                  fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="1.5"/>
                            <line id="Line_294" data-name="Line 294" y2="3.206" transform="translate(156.497 698.36)"
                                  fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="1.5"/>
                            <line id="Line_295" data-name="Line 295" y2="3.206" transform="translate(177.889 698.36)"
                                  fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="1.5"/>
                        </g>
                        <path id="Path_1331" data-name="Path 1331" d="M151.4,675.351h64.928v70.937H127.656v-24.8"
                              fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="1.5"/>
                    </g>
                </g>
            </g>
            <g id="Group_789" data-name="Group 789">
                <circle id="Ellipse_235" data-name="Ellipse 235" cx="19.806" cy="19.806" r="19.806"
                        transform="translate(165.299 623.486)" fill="none" stroke="#baa082" strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1.5"/>
                <g id="Group_788" data-name="Group 788">
                    <ellipse id="Ellipse_236" data-name="Ellipse 236" cx="8.301" cy="8.013" rx="8.301" ry="8.013"
                             transform="translate(175.5 633.747)" fill="none" stroke="#baa082" strokeLinecap="round"
                             strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_1" data-name="Line 1" x2="5.147" y2="5.291" transform="translate(189.563 647.547)"
                          fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
            </g>
            <g id="Group_793" data-name="Group 793">
                <rect id="Rectangle_701" data-name="Rectangle 701" width="88.671" height="70.937"
                      transform="translate(62.449 650.556)" fill="none" stroke="#007d7d" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
                <g id="Group_790" data-name="Group 790">
                    <path id="Path_1332" data-name="Path 1332"
                          d="M97.093,697.474v-4.558c0-4.452,3.224-8.061,7.2-8.061h25.9c3.978,0,7.2,3.609,7.2,8.061v4.557"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1333" data-name="Path 1333" d="M94.689,704.736a4.025,4.025,0,1,1,3.734-2.51"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1334" data-name="Path 1334" d="M135.968,701.951a4.031,4.031,0,1,1,3.834,2.785"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1335" data-name="Path 1335" d="M132.547,715.063H130.3l-1.612-4.378h5.473Z"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1336" data-name="Path 1336" d="M104.192,715.063h-2.249l-1.612-4.378H105.8Z"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_13" data-name="Path 13" d="M105.662,692.023l2.245,1.658-2.245,1.677" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_13-2" data-name="Path 13" d="M111.27,692.023l-2.245,1.658,2.245,1.677" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_13-3" data-name="Path 13" d="M123.221,692.023l2.245,1.658-2.245,1.677" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_13-4" data-name="Path 13" d="M128.93,692.023l-2.245,1.658,2.245,1.677" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <rect id="Rectangle_702" data-name="Rectangle 702" width="45.113" height="8.46" rx="4.23"
                          transform="translate(94.689 702.225)" fill="none" stroke="#007d7d" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
                <g id="Group_791" data-name="Group 791">
                    <rect id="Rectangle_703" data-name="Rectangle 703" width="12.898" height="16.292" rx="4"
                          transform="translate(70.336 698.771)" fill="none" stroke="#007d7d" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1337" data-name="Path 1337" d="M81.362,688.412H72.207l1.506-7.113h6.143Z" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <circle id="Ellipse_237" data-name="Ellipse 237" cx="3.743" cy="3.743" r="3.743"
                            transform="translate(73.042 691.285)" fill="none" stroke="#007d7d" strokeLinecap="round"
                            strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_296" data-name="Line 296" y2="2.873" transform="translate(76.785 688.412)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_297" data-name="Line 297" x2="12.898" transform="translate(70.336 709.59)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_298" data-name="Line 298" x2="12.898" transform="translate(70.336 704.308)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
                <g id="Group_792" data-name="Group 792">
                    <rect id="Rectangle_704" data-name="Rectangle 704" width="15.167" height="19.882"
                          transform="translate(98.853 656.981)" fill="none" stroke="#007d7d" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="1.5"/>
                    <circle id="Ellipse_238" data-name="Ellipse 238" cx="2.585" cy="2.585" r="2.585"
                            transform="translate(105.12 660.514)" fill="#007d7d"/>
                    <path id="Path_1338" data-name="Path 1338"
                          d="M98.853,667.915s3.123-1.437,8.237,3.522c2.1,2.036,4.773,1.4,6.931,1.4" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <rect id="Rectangle_705" data-name="Rectangle 705" width="15.167" height="19.882"
                          transform="translate(135.638 676.862) rotate(-180)" fill="none" stroke="#007d7d"
                          strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1339" data-name="Path 1339"
                          d="M135.638,661.636a7.076,7.076,0,0,0-7.3,6.086c-.873,5.066-5.714,5.115-7.872,5.115"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <circle id="Ellipse_239" data-name="Ellipse 239" cx="1.292" cy="1.292" r="1.292"
                            transform="translate(130.271 671.291)" fill="#007d7d"/>
                </g>
            </g>
        </g>
        <rect id="Rectangle_706" data-name="Rectangle 706" width="155" height="155" fill="none"/>
    </svg>
)

export const FittedIcon = ({className}) => (
    <svg id="fitted_icon" xmlns="http://www.w3.org/2000/svg" width="155" height="155" viewBox="0 0 155 155"
         className={className}>
        <g id="Group_778" data-name="Group 778" transform="translate(-474.557 -608.199)">
            <path id="Path_1298" data-name="Path 1298"
                  d="M498.406,661.427V626.633h22.456l.21,16.88h0l30.985-25.406,75,61.495-15.95.009v73.681h-118.1v-73.74l-15.95.05,21.349-18.174Z"
                  fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_773" data-name="Group 773">
                <path id="Path_1299" data-name="Path 1299"
                      d="M569.535,699.4h12.531c5.122,0,9.274,4.648,9.274,10.38v5.868" fill="none" stroke="#007d7d"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1300" data-name="Path 1300"
                      d="M539.439,715.653v-5.87c0-5.732,4.152-10.38,9.274-10.38h3.228" fill="none" stroke="#007d7d"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1301" data-name="Path 1301" d="M536.343,725a5.185,5.185,0,1,1,4.808-3.232" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1302" data-name="Path 1302" d="M589.519,721.479A5.191,5.191,0,1,1,594.436,725"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1303" data-name="Path 1303" d="M585.094,738.3h-2.9l-2.076-5.637h7.048Z" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1304" data-name="Path 1304" d="M548.581,738.3h-2.9l-2.076-5.637h7.048Z" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_13" data-name="Path 13" d="M575.156,710.163l.819.6-.8.6" fill="none" stroke="#007d7d"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_13-2" data-name="Path 13" d="M580.122,708.633l-2.891,2.135,2.891,2.159" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1305" data-name="Path 1305"
                      d="M555.5,732.665H541.79a5.448,5.448,0,0,1-5.447-5.448h0a5.447,5.447,0,0,1,5.447-5.447h.582"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_285" data-name="Line 285" x1="5.549" transform="translate(561.476 732.665)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1306" data-name="Path 1306"
                      d="M568.559,721.77h20.43a5.447,5.447,0,0,1,5.447,5.447h0a5.448,5.448,0,0,1-5.447,5.448h-15.6"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <g id="Group_774" data-name="Group 774">
                <rect id="Rectangle_692" data-name="Rectangle 692" width="16.608" height="20.979" rx="4.586"
                      transform="translate(504.984 717.323)" fill="none" stroke="#007d7d" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1307" data-name="Path 1307" d="M519.182,703.983H507.393l1.939-9.159h7.91Z" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_226" data-name="Ellipse 226" cx="4.82" cy="4.82" r="4.82"
                        transform="translate(508.468 707.683)" fill="none" stroke="#007d7d" strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_286" data-name="Line 286" y2="3.7" transform="translate(513.288 703.983)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_287" data-name="Line 287" x2="16.608" transform="translate(504.984 731.254)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_288" data-name="Line 288" x2="16.608" transform="translate(504.984 724.452)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <g id="Group_775" data-name="Group 775">
                <rect id="Rectangle_693" data-name="Rectangle 693" width="19.531" height="25.602"
                      transform="translate(528.705 661.508)" fill="none" stroke="#007d7d" strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_227" data-name="Ellipse 227" cx="3.328" cy="3.328" r="3.328"
                        transform="translate(536.775 666.059)" fill="#007d7d"/>
                <path id="Path_1308" data-name="Path 1308"
                      d="M528.705,675.589s4.022-1.851,10.607,4.535c2.705,2.622,6.146,1.8,8.925,1.8" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1309" data-name="Path 1309" d="M567.7,687.11h8.377v-25.6H556.543v15.524" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1310" data-name="Path 1310"
                      d="M576.074,667.5s-7.97-.43-9.395,7.837c-.758,4.4-3.839,5.86-6.618,6.345" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_228" data-name="Ellipse 228" cx="1.664" cy="1.664" r="1.664"
                        transform="translate(569.162 679.937)" fill="#007d7d"/>
            </g>
            <g id="Group_777" data-name="Group 777">
                <path id="Path_1311" data-name="Path 1311"
                      d="M546.3,718.137v-7.282a14.435,14.435,0,1,1,28.869-.017v1.613a5.547,5.547,0,0,1-10.911,1.416"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1312" data-name="Path 1312" d="M555.482,684.2a3.661,3.661,0,1,1,4.492-2.23" fill="none"
                      stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_67" data-name="Ellipse 67" cx="7.248" cy="7.248" r="7.248"
                        transform="translate(553.501 681.926)" fill="none" stroke="#baa082" strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1027" data-name="Path 1027" d="M564.078,690.734a3.841,3.841,0,0,1-6.649,0" fill="none"
                      stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1029" data-name="Path 1029" d="M562.113,702.791l3.536-5.246" fill="none" stroke="#baa082"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1029-2" data-name="Path 1029" d="M555.7,697.545l3.546,5.259" fill="none" stroke="#baa082"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <g id="Group_776" data-name="Group 776">
                    <path id="Path_1313" data-name="Path 1313" d="M562.135,711.2h-3.742l-1.053-8.4h6.65l-.693,5.528"
                          fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1314" data-name="Path 1314" d="M563.317,708.334l.391-3.28h2.423l-.375,2.22"
                          fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
                <circle id="Ellipse_229" data-name="Ellipse 229" cx="3.223" cy="3.223" r="3.223"
                        transform="translate(562.113 707.614)" fill="none" stroke="#baa082" strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1315" data-name="Path 1315" d="M547.965,723.465A3.185,3.185,0,0,0,552,720.4V709.333"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_289" data-name="Line 289" x1="0.88" y2="17.83" transform="translate(560.931 725.882)"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1316" data-name="Path 1316"
                      d="M556.042,743.712l-.991-20.079a3.441,3.441,0,0,1,2.505-3.482" fill="none" stroke="#baa082"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1317" data-name="Path 1317"
                      d="M570.939,737.755,558,720.929a3.438,3.438,0,0,1,.767-4.922h0a3.439,3.439,0,0,1,4.879,1.008l10.881,17.476"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1318" data-name="Path 1318" d="M571.075,717.893h0a4.1,4.1,0,0,1-4.1,4.1h-.234"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1319" data-name="Path 1319" d="M551.776,721.571a4.081,4.081,0,0,0,1.806.418h1.8"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1320" data-name="Path 1320" d="M562.359,746.716a3.873,3.873,0,0,0-7.746,0Z" fill="none"
                      stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1321" data-name="Path 1321" d="M578.051,736.3a3.873,3.873,0,1,0-6.286,4.526Z" fill="none"
                      stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_230" data-name="Ellipse 230" cx="3.223" cy="3.223" r="3.223"
                        transform="translate(542.372 718.059)" fill="none" stroke="#baa082" strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
        </g>
        <rect id="Rectangle_694" data-name="Rectangle 694" width="155" height="155" fill="none"/>
    </svg>
)

export const UpArrowIcon = ({className}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={className}>
        <path id="Arrow_down" data-name="Arrow down" d="M227.391,183.229l-6.159-6.884L215,183.229"
              transform="translate(-212.195 -170.787)" fill="none" stroke="#707070" strokeLinejoin="round"
              strokeWidth="2"/>
    </svg>
)


export const RightArrowIcon = ({className}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={className}>
        <path id="Path_13" data-name="Path 13" d="M426.693,97.578l8,7.456-8,7.544"
              transform="translate(-421.693 -96.078)" fill="none" stroke="currentColor" strokeLinejoin="round"
              strokeWidth="2"/>
    </svg>
)

export const LeftArrowIcon = ({className}) => (
    <svg id="left_arrow_icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
         className={className}>
        <path id="Path_13" data-name="Path 13" d="M396.564,112.578l-8-7.456,8-7.544"
              transform="translate(-383.563 -96.078)" fill="none" stroke="currentColor" strokeLinejoin="round"
              strokeWidth="2"/>
    </svg>
)

export const LogoFooter = ({className}) => (
    <svg id="logo_footer" xmlns="http://www.w3.org/2000/svg" width="126" height="18" viewBox="0 0 126 18"
         className={className}>
        <g id="Group_797" data-name="Group 797" transform="translate(-783.352 -49.344)">
            <rect id="Rectangle_719" data-name="Rectangle 719" width="4.384" height="15.794"
                  transform="translate(803.291 50.186)" fill="#0d5261"/>
            <path id="Path_1350" data-name="Path 1350"
                  d="M792.818,62.343l-4.291-12.157h-4.669l5.966,15.794h5.994L801.7,50.186h-4.393Z" fill="#0d5261"/>
            <path id="Path_1351" data-name="Path 1351"
                  d="M822.644,59.072a4.072,4.072,0,0,0-1.257-1.145,3.856,3.856,0,0,0,1.07-1.19,3.98,3.98,0,0,0,.534-2.026,3.8,3.8,0,0,0-1.812-3.408,8.8,8.8,0,0,0-4.748-1.117h-6.285V65.979h6.738a9,9,0,0,0,3.376-.576,5.078,5.078,0,0,0,2.243-1.66,4.117,4.117,0,0,0,.8-2.5,3.636,3.636,0,0,0-.66-2.17Zm-8.33-5.48H816.5a3.379,3.379,0,0,1,1.768.352,1.061,1.061,0,0,1,.485.963,1.136,1.136,0,0,1-.537,1.053h0a3.893,3.893,0,0,1-1.944.367h-1.961Zm4.22,8.6a3.2,3.2,0,0,1-1.694.376h-2.525l0-2.825h2.527a3.4,3.4,0,0,1,1.767.341,1.115,1.115,0,0,1,.463,1.018A1.18,1.18,0,0,1,818.534,62.2Z"
                  fill="#0d5261"/>
            <path id="Path_1352" data-name="Path 1352"
                  d="M870.011,60.274a5.231,5.231,0,0,0,1.431-3.8,5.6,5.6,0,0,0-2.082-4.62q-2.086-1.672-6.251-1.673h-6.6V65.976h4.535V62.542h2.834a2.616,2.616,0,0,1,.677.077c1.854.5,1.791,2.651,1.791,3.353h4.252c.062-1.921-.222-3.651-1.574-4.874a6.442,6.442,0,0,0,.991-.821Zm-4.131-1.943a5.8,5.8,0,0,1-3.061.609h-1.786l0-4.989h1.812a6.077,6.077,0,0,1,2.919.582,1.99,1.99,0,0,1,1.048,1.885A2.12,2.12,0,0,1,865.88,58.331Z"
                  fill="#0d5261"/>
            <path id="Path_1353" data-name="Path 1353"
                  d="M903.537,50.725l-3.479,4.763-3.667-4.763h-5.606l6.679,8.591v6.533h4.915V59.193l6.47-8.469Z"
                  fill="#0d5261"/>
            <path id="Path_1354" data-name="Path 1354"
                  d="M885.956,50.725l-.577,1.269-.144-.118a7.116,7.116,0,0,0-4.587-1.612,7.587,7.587,0,0,0-7.885,7.974,7.7,7.7,0,0,0,7.355,8.025c.176.008.353.01.53,0a7.1,7.1,0,0,0,4.53-1.569l.146-.119.489,1.17h3.84V50.725Zm-4.444,11.157c-.053,0-.106,0-.159,0a3.627,3.627,0,1,1,3.568-3.686v.053A3.522,3.522,0,0,1,881.512,61.882Z"
                  fill="#0d5261"/>
            <path id="Path_1355" data-name="Path 1355"
                  d="M841.945,54.717h4.07V65.976h4.536V54.717h4.071V50.182H841.945Z" fill="#0d5261"/>
            <path id="Path_1356" data-name="Path 1356"
                  d="M832.98,50.1a8,8,0,0,0-8.3,7.686c-.007.183-.008.366,0,.549,0,4.648,3.306,8.235,8.641,8.235a7.911,7.911,0,0,0,6.8-3.211l-3.743-2.153a4.074,4.074,0,0,1-3,1.185c-1.716,0-3.15-.562-3.742-2.183h11.08a9.172,9.172,0,0,0,.184-1.87l0,0A7.875,7.875,0,0,0,832.98,50.1Zm-3.432,6.613a3.513,3.513,0,0,1,6.706,0Z"
                  fill="#0d5261"/>
        </g>
        <rect id="Rectangle_720" data-name="Rectangle 720" width="126" height="18" fill="none"/>
    </svg>
)

export const FacebookIcon = ({className}) => (
    <svg id="facebook_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
         className={className}>
        <g id="Facebook" transform="translate(-211.462 -87.417)">
            <circle id="Ellipse_12" data-name="Ellipse 12" cx="12" cy="12" r="12" transform="translate(211.462 87.417)"
                    fill="currentColor"/>
            <path id="Facebook_icon-2" data-name="Facebook icon"
                  d="M221.549,105.417h2.284v-5.5h1.542l.2-1.942h-1.742V96.834a.466.466,0,0,1,.4-.525.446.446,0,0,1,.089,0h1.228V94.42l-1.692-.007a2.142,2.142,0,0,0-2.3,1.971,2.081,2.081,0,0,0,0,.335v1.257h-1.092v1.942h1.087Z"
                  fill="#ededed"/>
        </g>
        <rect id="Rectangle_502" data-name="Rectangle 502" width="24" height="24" transform="translate(0)" fill="none"/>
    </svg>
)

export const InstagramIcon = ({className}) => (
    <svg id="instagram_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
         className={className}>
        <g id="Instagram" transform="translate(-269.113 -87.417)">
            <circle id="Ellipse_13-2" data-name="Ellipse 13-2" cx="12" cy="12" r="12"
                    transform="translate(269.113 87.417)" fill="currentColor"/>
            <g id="Instagram_icon-2" data-name="Instagram icon">
                <path id="Path_831" data-name="Path 831"
                      d="M281.114,94.5c1.6,0,1.792.006,2.425.035a3.34,3.34,0,0,1,1.114.207,1.99,1.99,0,0,1,1.139,1.139A3.34,3.34,0,0,1,286,96.993c.029.633.035.822.035,2.424s-.006,1.792-.035,2.425a3.34,3.34,0,0,1-.207,1.114,1.99,1.99,0,0,1-1.139,1.139,3.34,3.34,0,0,1-1.114.207c-.633.029-.822.035-2.425.035s-1.792-.006-2.424-.035a3.34,3.34,0,0,1-1.114-.207,1.99,1.99,0,0,1-1.139-1.139,3.339,3.339,0,0,1-.207-1.114c-.029-.633-.035-.822-.035-2.425s.006-1.792.035-2.424a3.339,3.339,0,0,1,.207-1.114,1.99,1.99,0,0,1,1.139-1.139,3.34,3.34,0,0,1,1.114-.207c.633-.029.822-.035,2.424-.035m0-1.081c-1.63,0-1.834.007-2.474.036a4.39,4.39,0,0,0-1.456.279,3.07,3.07,0,0,0-1.755,1.755,4.4,4.4,0,0,0-.279,1.457c-.029.64-.036.844-.036,2.474s.007,1.834.036,2.474a4.391,4.391,0,0,0,.279,1.456,3.07,3.07,0,0,0,1.755,1.755,4.39,4.39,0,0,0,1.456.279c.64.029.844.036,2.474.036s1.834-.007,2.474-.036a4.39,4.39,0,0,0,1.456-.279,3.07,3.07,0,0,0,1.755-1.755,4.391,4.391,0,0,0,.279-1.456c.029-.64.036-.844.036-2.474s-.007-1.834-.036-2.474a4.4,4.4,0,0,0-.279-1.457,3.07,3.07,0,0,0-1.755-1.755,4.39,4.39,0,0,0-1.456-.279c-.64-.029-.844-.036-2.474-.036"
                      fill="#ededed"/>
                <path id="Path_832" data-name="Path 832"
                      d="M281.114,96.336a3.081,3.081,0,1,0,3.081,3.081h0a3.08,3.08,0,0,0-3.081-3.081m0,5.081a2,2,0,1,1,2-2,2,2,0,0,1-2,2"
                      fill="#ededed"/>
                <path id="Path_833" data-name="Path 833" d="M285.036,96.214a.72.72,0,1,1-.72-.72.72.72,0,0,1,.72.72h0"
                      fill="#ededed"/>
            </g>
        </g>
        <rect id="Rectangle_503" data-name="Rectangle 503" width="24" height="24" transform="translate(0)" fill="none"/>
    </svg>
)

export const PinterestIcon = ({className}) => (
    <svg id="pinterest_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
         className={className}>
        <g id="Pinterest" transform="translate(-311.722 -87.417)">
            <circle id="Ellipse_13" data-name="Ellipse 13" cx="12" cy="12" r="12" transform="translate(311.722 87.417)"
                    fill="currentColor"/>
            <path id="Pinterest_icon-2" data-name="Pinterest icon"
                  d="M320.434,101.356a.237.237,0,0,0,.32-.1.24.24,0,0,0,.025-.074c.032-.12.107-.427.141-.553a.338.338,0,0,0-.1-.385,1.982,1.982,0,0,1-.455-1.351,3.264,3.264,0,0,1,3.229-3.3c.055,0,.11,0,.165,0a2.58,2.58,0,0,1,2.849,2.279,2.729,2.729,0,0,1,.015.362c0,1.988-.88,3.665-2.186,3.665a1.067,1.067,0,0,1-1.12-1.009,1.037,1.037,0,0,1,.032-.319,14.925,14.925,0,0,0,.608-2.446.922.922,0,0,0-.8-1.028.909.909,0,0,0-.127-.007c-.737,0-1.33.762-1.33,1.785a2.637,2.637,0,0,0,.22,1.09s-.755,3.2-.887,3.755a7.684,7.684,0,0,0-.02,2.62.09.09,0,0,0,.1.076.093.093,0,0,0,.061-.037,7.369,7.369,0,0,0,1.244-2.256c.085-.306.485-1.894.485-1.894a1.975,1.975,0,0,0,1.684.859c2.216,0,3.719-2.021,3.719-4.723a4.12,4.12,0,0,0-4.28-3.954l-.084,0c-3.275,0-4.927,2.348-4.927,4.305A2.654,2.654,0,0,0,320.434,101.356Z"
                  fill="#ededed"/>
        </g>
        <rect id="Rectangle_504" data-name="Rectangle 504" width="24" height="24" transform="translate(0)" fill="none"/>
    </svg>
)

export const CloseIcon = ({className}) => (
    <svg id="close_icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
         className={className}>
        <g id="Close_icon-2" data-name="Close icon" transform="translate(-320.511 -145.21)">
            <line id="Line_4" data-name="Line 4" x2="20" y2="20" transform="translate(321.511 146.21)" fill="none"
                  stroke="currentColor" strokeWidth="2"/>
            <line id="Line_5" data-name="Line 5" x1="20" y2="20" transform="translate(321.511 146.21)" fill="none"
                  stroke="currentColor" strokeWidth="2"/>
        </g>
    </svg>
)

export const CookieIcon = ({className}) => (
    <svg id="cookies_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
         className={className}>
        <rect id="Rectangle_576" data-name="Rectangle 576" width="35" height="35" fill="none"/>
        <g id="Group_650" data-name="Group 650" transform="translate(53.004 -353.852)">
            <path id="Path_1057" data-name="Path 1057"
                  d="M-46.414,368.131a10.76,10.76,0,0,0-4.445,7.08,10.883,10.883,0,0,0,8.989,12.47,10.854,10.854,0,0,0,8.226-1.946,10.755,10.755,0,0,0,3.367-4.014"
                  fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <circle id="Ellipse_120" data-name="Ellipse 120" cx="1.636" cy="1.636" r="1.636"
                    transform="translate(-42.368 382.467)" fill="#fff"/>
            <circle id="Ellipse_121" data-name="Ellipse 121" cx="1.636" cy="1.636" r="1.636"
                    transform="translate(-47.492 375.221)" fill="#fff"/>
            <circle id="Ellipse_122" data-name="Ellipse 122" cx="1.09" cy="1.09" r="1.09"
                    transform="translate(-46.972 380.736)" fill="#fff"/>
            <path id="Path_1058" data-name="Path 1058"
                  d="M-36.222,356.137A13.082,13.082,0,0,0-46.413,368.91,13.187,13.187,0,0,0-33.2,382.041a13.153,13.153,0,0,0,9.45-3.953A12.991,12.991,0,0,0-20,369.6a3.98,3.98,0,0,1-4.5-3.413c-8.226,2.352-7.024-5.41-7.024-5.41S-36.675,361.365-36.222,356.137Z"
                  fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <circle id="Ellipse_123" data-name="Ellipse 123" cx="1.982" cy="1.982" r="1.982"
                    transform="translate(-39.806 362.396)" fill="#fff"/>
            <circle id="Ellipse_124" data-name="Ellipse 124" cx="1.982" cy="1.982" r="1.982"
                    transform="translate(-29.236 370.149)" fill="#fff"/>
            <circle id="Ellipse_125" data-name="Ellipse 125" cx="1.982" cy="1.982" r="1.982"
                    transform="translate(-39.097 372.792)" fill="#fff"/>
            <circle id="Ellipse_126" data-name="Ellipse 126" cx="1.321" cy="1.321" r="1.321"
                    transform="translate(-35.843 368.131)" fill="#fff"/>
            <circle id="Ellipse_127" data-name="Ellipse 127" cx="1.321" cy="1.321" r="1.321"
                    transform="translate(-31.879 375.435)" fill="#fff"/>
            <circle id="Ellipse_128" data-name="Ellipse 128" cx="1.321" cy="1.321" r="1.321"
                    transform="translate(-29.236 354.294)" fill="#fff"/>
            <circle id="Ellipse_129" data-name="Ellipse 129" cx="1.982" cy="1.982" r="1.982"
                    transform="translate(-23.968 358.745)" fill="none" stroke="#fff" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="1.5"/>
            <circle id="Ellipse_130" data-name="Ellipse 130" cx="1.321" cy="1.321" r="1.321"
                    transform="translate(-42.866 368.131)" fill="#fff"/>
        </g>
    </svg>
)

export const BurgerMenuIcon = ({className}) => (
    <svg viewBox="0 0 100 64" width="25" height="16" className={className}>
        <rect width="100" height="8" fill="currentColor"/>
        <rect y="24" width="100" height="8" fill="currentColor"/>
        <rect y="48" width="100" height="8" fill="currentColor"/>
    </svg>
)

export const ShowPasswordInputIcon = ({className}) => (
    <svg id="hide_password_icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
         className={className}>
        <g id="View_password_icon" data-name="View password icon" transform="translate(-360.168 -145.867)">
            <g id="Path_851" data-name="Path 851">
                <path id="Path_1040" data-name="Path 1040"
                      d="M371.175,152.867a14.027,14.027,0,0,0-6.753,2.026,17.514,17.514,0,0,0-2.834,2.012c1.269,1.184,5.17,3.962,9.587,3.962a13.874,13.874,0,0,0,6.836-2.016,15.7,15.7,0,0,0,2.761-2.027,15.355,15.355,0,0,0-9.6-3.957m0-1c6.008,0,10.987,4.572,10.878,5s-4.87,5-10.878,5-11.056-4.672-10.878-5h0C359.952,156.867,365.168,151.867,371.175,151.867Z"
                      fill="#707070"/>
            </g>
            <g id="Ellipse_14" data-name="Ellipse 14">
                <circle id="Ellipse_95" data-name="Ellipse 95" cx="4.5" cy="4.5" r="4.5"
                        transform="translate(366.798 152.367)" fill="none" stroke="#707070" strokeWidth="1"/>
            </g>
        </g>
    </svg>
)

export const HidePasswordIcon = ({className}) => (
    <svg id="show_password_icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
         className={className}>
        <g id="View_password_icon" data-name="View password icon" transform="translate(-404.063 -145.867)">
            <g id="Path_851" data-name="Path 851">
                <path id="Path_1041" data-name="Path 1041"
                      d="M415.07,151.867c6.008,0,10.987,4.572,10.878,5s-4.87,5-10.878,5-11.057-4.672-10.878-5h0C403.846,156.867,409.063,151.867,415.07,151.867Z"
                      fill="#707070"/>
            </g>
            <g id="Ellipse_14" data-name="Ellipse 14">
                <circle id="Ellipse_96" data-name="Ellipse 96" cx="4.5" cy="4.5" r="4.5"
                        transform="translate(410.693 152.367)" fill="none" stroke="#fff" strokeWidth="1"/>
            </g>
        </g>
        <rect id="Rectangle_509" data-name="Rectangle 509" width="22" height="22" fill="none"/>
    </svg>
)

export const AvatarIcon = ({className}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" className={className}>
        <defs>
            <clipPath id="a">
                <circle data-name="Ellipse 90" cx="14.5" cy="14.5" r="14.5" transform="translate(63.198 284.184)"
                        fill="none"/>
            </clipPath>
        </defs>
        <g data-name="Avatar icon">
            <g data-name="Group 593">
                <g data-name="Group 592" clipPath="url(#a)" transform="translate(-62.698 -283.684)">
                    <g data-name="Mask Group 11">
                        <g data-name="Profile icon" fill="none" stroke="#007d7d" strokeWidth="1.5">
                            <circle data-name="Ellipse 67" cx="5.762" cy="5.762" r="5.762"
                                    transform="translate(71.922 289.184)" strokeMiterlimit="10"/>
                            <path data-name="Path 1027" d="M80.331 296.187a3.054 3.054 0 01-5.286 0"
                                  strokeMiterlimit="10"/>
                            <path data-name="Path 1028" d="M66.2 312.184a11.476 11.476 0 1122.952-.014v.014"
                                  strokeMiterlimit="10"/>
                            <path data-name="Path 1029" d="M73.668 301.6l3.956 5.868 3.956-5.868"
                                  strokeLinejoin="round"/>
                        </g>
                    </g>
                </g>
            </g>
            <g data-name="Ellipse 83" fill="none" transform="translate(-62.698 -283.684)">
                <circle data-name="Ellipse 91" cx="15" cy="15" r="15" transform="translate(62.698 283.684)"/>
                <circle data-name="Ellipse 92" cx="14.25" cy="14.25" r="14.25" transform="translate(63.448 284.434)"
                        stroke="#007d7d" strokeWidth="1.5"/>
            </g>
        </g>
    </svg>
)

export const DownArrowIcon = ({className}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={className}>
        <path id="Arrow_down" data-name="Arrow down" d="M176.5,176.345l6.159,6.884,6.232-6.884"
              transform="translate(-173.696 -170.787)" fill="none" stroke="currentColor" strokeLinejoin="round"
              strokeWidth="2"/>
    </svg>
)

export const OnlinePurchaseIcon = ({className}) => (
    <svg id="online_purchase_icon" xmlns="http://www.w3.org/2000/svg" width="155" height="155" viewBox="0 0 155 155"
         className={className}>
        <g id="Group_830" data-name="Group 830" transform="translate(-264.748 -346.248)">
            <path id="Path_1478" data-name="Path 1478" d="M401.806,390.968a67.025,67.025,0,0,1,4.682,53.916" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1479" data-name="Path 1479" d="M327.377,358.277a67.176,67.176,0,0,1,41.066,3.012" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1480" data-name="Path 1480" d="M276.555,419.051a66.5,66.5,0,0,1,13.2-35.895" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1481" data-name="Path 1481" d="M308.274,480.157a67.17,67.17,0,0,1-19.959-18.9" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1482" data-name="Path 1482" d="M388.384,444.883h24.759l-14.551,35.272" fill="none"
                  stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <rect id="Rectangle_740" data-name="Rectangle 740" width="4.737" height="7.938"
                  transform="translate(297.8 486.852)" fill="none" stroke="#007d7d" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="1.5"/>
            <rect id="Rectangle_741" data-name="Rectangle 741" width="4.737" height="7.938"
                  transform="translate(330.704 486.852)" fill="none" stroke="#007d7d" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="1.5"/>
            <rect id="Rectangle_742" data-name="Rectangle 742" width="4.737" height="7.938"
                  transform="translate(360.952 486.852)" fill="none" stroke="#007d7d" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="1.5"/>
            <rect id="Rectangle_743" data-name="Rectangle 743" width="4.737" height="7.938"
                  transform="translate(397.419 486.852)" fill="none" stroke="#007d7d" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="1.5"/>
            <line id="Line_303" data-name="Line 303" y2="6.696" transform="translate(333.073 480.155)" fill="none"
                  stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1483" data-name="Path 1483" d="M386.124,426.615a4.676,4.676,0,1,0-5.739-2.849" fill="#fff"
                  stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
            <circle id="Ellipse_67" data-name="Ellipse 67" cx="9.259" cy="9.259" r="9.259"
                    transform="translate(370.137 423.711)" fill="#fff" stroke="#baa082" strokeLinejoin="round"
                    strokeWidth="1.5"/>
            <path id="Path_1027" data-name="Path 1027" d="M375.142,434.963a4.908,4.908,0,0,0,8.494,0" fill="#fff"
                  stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1484" data-name="Path 1484" d="M388.232,458.216v0" fill="#fff" stroke="#baa082"
                  strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1485" data-name="Path 1485"
                  d="M328.025,470.942a1.32,1.32,0,0,1,.226-.021c1.307,0,2.366,2.068,2.366,4.617s-1.059,4.617-2.366,4.617a1.9,1.9,0,0,1-1.527-1.091"
                  fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_823" data-name="Group 823">
                <g id="Group_822" data-name="Group 822">
                    <path id="Path_1486" data-name="Path 1486"
                          d="M325.355,474.976c0,4.177-1.193,5.179-2.664,5.179s-2.664-1-2.664-5.179,1.958-9.948,2.664-9.948C323.461,465.028,325.355,470.8,325.355,474.976Z"
                          fill="#fff" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_304" data-name="Line 304" x2="3.429" transform="translate(320.988 468.81)"
                          fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_305" data-name="Line 305" x2="4.985" transform="translate(320.201 472.592)"
                          fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_306" data-name="Line 306" x2="5.223" transform="translate(320.08 476.374)"
                          fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
                <path id="Path_1487" data-name="Path 1487"
                      d="M322.691,465.028s5.35,2.422,5.339,7.564c0,.369,0,2.917,0,3.273.022,2.361-1.332,4-3.049,4.123l-2.29.167"
                      fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <line id="Line_307" data-name="Line 307" x2="35.897" transform="translate(338.584 470.921)" fill="none"
                  stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
            <line id="Line_308" data-name="Line 308" x2="3.395" transform="translate(327.836 470.921)" fill="none"
                  stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1488" data-name="Path 1488" d="M390.808,470.373a8.064,8.064,0,0,1-7.5,9.782H331.834"
                  fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1489" data-name="Path 1489"
                  d="M338.774,470.942a1.322,1.322,0,0,1,.227-.021c1.306,0,2.365,2.068,2.365,4.617s-1.059,4.617-2.365,4.617a1.9,1.9,0,0,1-1.527-1.091"
                  fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_825" data-name="Group 825">
                <g id="Group_824" data-name="Group 824">
                    <path id="Path_1490" data-name="Path 1490"
                          d="M336.1,474.976c0,4.177-1.193,5.179-2.664,5.179s-2.664-1-2.664-5.179,1.958-9.948,2.664-9.948C334.21,465.028,336.1,470.8,336.1,474.976Z"
                          fill="#fff" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_309" data-name="Line 309" x2="3.429" transform="translate(331.737 468.81)"
                          fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_310" data-name="Line 310" x2="4.985" transform="translate(330.951 472.592)"
                          fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_311" data-name="Line 311" x2="5.223" transform="translate(330.829 476.374)"
                          fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
                <path id="Path_1491" data-name="Path 1491"
                      d="M333.44,465.028s5.35,2.422,5.339,7.564c0,.369,0,2.917,0,3.273.022,2.361-1.332,4-3.049,4.123l-2.29.167"
                      fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <path id="Path_1029" data-name="Path 1029" d="M373.212,443.577l5.923,8.785,5.922-8.785" fill="none"
                  stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_826" data-name="Group 826">
                <path id="Path_1492" data-name="Path 1492" d="M358.4,445.42l-4.807.4-1.859-8.791,7.034-.59Z" fill="none"
                      stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1493" data-name="Path 1493" d="M355.781,436.692l1.491-4.239,2.011-1.782" fill="none"
                      stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <circle id="Ellipse_256" data-name="Ellipse 256" cx="3.836" cy="3.836" r="3.836"
                    transform="translate(348.016 441.987)" fill="#fff" stroke="#baa082" strokeLinejoin="round"
                    strokeWidth="1.5"/>
            <path id="Path_1494" data-name="Path 1494" d="M348.9,452.766a6.659,6.659,0,0,1,.406-4.088" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1495" data-name="Path 1495" d="M369.327,454.332c0,8.187-3.572,9.07-7.145,8.7" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_828" data-name="Group 828">
                <path id="Path_1496" data-name="Path 1496" d="M342.308,467.846h21.883l-6.3-15.085H336.012Z" fill="#fff"
                      stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1497" data-name="Path 1497" d="M369.729,467.846H342.308v3.076h32.174" fill="#fff"
                      stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_312" data-name="Line 312" y2="3.076" transform="translate(364.191 467.846)" fill="none"
                      stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                <g id="Group_827" data-name="Group 827">
                    <ellipse id="Ellipse_257" data-name="Ellipse 257" cx="0.728" cy="0.377" rx="0.728" ry="0.377"
                             transform="translate(349.967 459.494) rotate(-76.265)" fill="none" stroke="#baa082"
                             strokeLinejoin="round" strokeWidth="1.5"/>
                    <ellipse id="Ellipse_258" data-name="Ellipse 258" cx="0.728" cy="0.377" rx="0.728" ry="0.377"
                             transform="translate(348.769 463.47) rotate(-76.265)" fill="none" stroke="#baa082"
                             strokeLinejoin="round" strokeWidth="1.5"/>
                    <ellipse id="Ellipse_259" data-name="Ellipse 259" cx="0.358" cy="0.767" rx="0.358" ry="0.767"
                             transform="translate(347.076 459.911) rotate(-62.814)" fill="none" stroke="#baa082"
                             strokeLinejoin="round" strokeWidth="1.5"/>
                    <ellipse id="Ellipse_260" data-name="Ellipse 260" cx="0.358" cy="0.767" rx="0.358" ry="0.767"
                             transform="translate(351.047 461.753) rotate(-62.814)" fill="none" stroke="#baa082"
                             strokeLinejoin="round" strokeWidth="1.5"/>
                    <ellipse id="Ellipse_261" data-name="Ellipse 261" cx="0.691" cy="0.397" rx="0.691" ry="0.397"
                             transform="translate(347.292 461.494) rotate(-20.91)" fill="none" stroke="#baa082"
                             strokeLinejoin="round" strokeWidth="1.5"/>
                    <ellipse id="Ellipse_262" data-name="Ellipse 262" cx="0.691" cy="0.397" rx="0.691" ry="0.397"
                             transform="translate(350.947 459.985) rotate(-20.91)" fill="none" stroke="#baa082"
                             strokeLinejoin="round" strokeWidth="1.5"/>
                    <ellipse id="Ellipse_263" data-name="Ellipse 263" cx="0.345" cy="0.797" rx="0.345" ry="0.797"
                             transform="translate(350.227 462.359) rotate(-26.195)" fill="none" stroke="#baa082"
                             strokeLinejoin="round" strokeWidth="1.5"/>
                    <ellipse id="Ellipse_264" data-name="Ellipse 264" cx="0.345" cy="0.797" rx="0.345" ry="0.797"
                             transform="translate(348.266 458.244) rotate(-26.195)" fill="none" stroke="#baa082"
                             strokeLinejoin="round" strokeWidth="1.5"/>
                    <ellipse id="Ellipse_265" data-name="Ellipse 265" cx="1.9" cy="2.211" rx="1.9" ry="2.211"
                             transform="translate(347.091 460.109) rotate(-34.333)" fill="#fff" stroke="#baa082"
                             strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
            </g>
            <path id="Path_1498" data-name="Path 1498"
                  d="M388.232,454.332v4.95c0,3.434-1.785,5.714-6.27,6.114-1.432.127-5.736.293-8.7-1.6" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <circle id="Ellipse_266" data-name="Ellipse 266" cx="3.836" cy="3.836" r="3.836"
                    transform="translate(365.591 460.171)" fill="#fff" stroke="#baa082" strokeLinejoin="round"
                    strokeWidth="1.5"/>
            <path id="Path_1499" data-name="Path 1499"
                  d="M359.03,455.487c1.686.391,3.245.007,4.29-2.619h0a17.184,17.184,0,0,1,33.073,6.53v1.92c0,6.183-4.192,10.089-12.277,10.7-5.566.42-11.534-.857-14.386-4.168"
                  fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1500" data-name="Path 1500" d="M353.315,449.369a9.908,9.908,0,0,0,1.511,3.393" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1501" data-name="Path 1501"
                  d="M398.593,480.155l14.551-35.272,4.1,5.241L402.1,486.852H297.8v-6.7Z" fill="none" stroke="#007d7d"
                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <circle id="Ellipse_267" data-name="Ellipse 267" cx="23.656" cy="23.656" r="23.656"
                    transform="translate(356.264 358.323)" fill="none" stroke="#baa082" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Orders_icon" data-name="Orders icon">
                <path id="Path_929" data-name="Path 929"
                      d="M383.171,391.428l-1.744,1.744-2.344-2.344-2.344,2.344-2.344-2.344-2.344,2.344v-20.56"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_930" data-name="Path 930"
                      d="M369.717,370.54h14.092a2.313,2.313,0,0,1,2.314,2.311h0V383.14" fill="none" stroke="#007d7d"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_132" data-name="Line 132" x2="7.252" transform="translate(375.512 374.658)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_133" data-name="Line 133" x2="3.626" transform="translate(375.512 377.967)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_134" data-name="Line 134" x2="5.51" transform="translate(375.512 381.276)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <g id="Group_484" data-name="Group 484">
                    <circle id="Ellipse_64" data-name="Ellipse 64" cx="5.202" cy="5.202" r="5.202"
                            transform="translate(382.057 383.014)" fill="none" stroke="#007d7d" strokeLinecap="round"
                            strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_931" data-name="Path 931" d="M384.67,388.356l1.708,1.708,3.125-3.126" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
                <path id="Path_932" data-name="Path 932" d="M367.38,372.876a2.337,2.337,0,0,1,4.672,0" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_933" data-name="Path 933" d="M372.052,372.876v5.876h-4.673v-5.876" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <circle id="Ellipse_268" data-name="Ellipse 268" cx="23.656" cy="23.656" r="23.656"
                    transform="translate(267.248 414.091)" fill="none" stroke="#baa082" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_829" data-name="Group 829">
                <ellipse id="Ellipse_269" data-name="Ellipse 269" cx="8.773" cy="8.469" rx="8.773" ry="8.469"
                         transform="translate(280.752 427.658)" fill="none" stroke="#007d7d" strokeLinecap="round"
                         strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_1" data-name="Line 1" x2="5.44" y2="5.592" transform="translate(295.615 442.243)"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <circle id="Ellipse_270" data-name="Ellipse 270" cx="23.656" cy="23.656" r="23.656"
                    transform="translate(288.8 352.708)" fill="none" stroke="#baa082" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Customisation_icon" data-name="Customisation icon">
                <g id="Group_486" data-name="Group 486">
                    <path id="Path_934" data-name="Path 934" d="M315.917,376.281l7.884,7.884-3.8,3.8-8.053-8.052"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_935" data-name="Path 935" d="M308.537,376.509l-7.947-7.948,3.8-3.8,7.691,7.691"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_135" data-name="Line 135" x1="1.985" y2="1.985"
                          transform="translate(305.044 367.393)" fill="none" stroke="#007d7d" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_136" data-name="Line 136" x1="1.893" y2="1.893" transform="translate(307.76 370.257)"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_137" data-name="Line 137" x1="1.916" y2="1.916"
                          transform="translate(316.323 378.969)" fill="none" stroke="#007d7d" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_138" data-name="Line 138" x1="1.991" y2="1.991"
                          transform="translate(319.008 381.634)" fill="none" stroke="#007d7d" strokeLinecap="round"
                          strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
                <g id="Group_487" data-name="Group 487">
                    <path id="Path_936" data-name="Path 936"
                          d="M309.815,381.864l-3.212-3.142a5,5,0,0,0-4.468,4.569c-.281,3.274-2.408,3.356-3.331,3.234a.075.075,0,0,0-.047.139C300.275,387.494,307.933,389.937,309.815,381.864Z"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_937" data-name="Path 937"
                          d="M322.309,365.873h0a3.472,3.472,0,0,0-4.89.455c-.031.036-.061.074-.09.112L306.6,378.718l3.212,3.141,12.038-11a3.473,3.473,0,0,0,.545-4.881C322.368,365.942,322.338,365.907,322.309,365.873Z"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
            </g>
        </g>
    </svg>
)

export const OneStopShopIcon = ({className}) => (
    <svg id="one_stop_shop_icon" xmlns="http://www.w3.org/2000/svg" width="155" height="155" viewBox="0 0 155 155"
         className={className}>
        <g id="Group_820" data-name="Group 820" transform="translate(-44.096 -347.691)">
            <path id="Path_1475" data-name="Path 1475" d="M154.24,385.022a60.86,60.86,0,0,1,27.4,44.154" fill="none"
                  stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" strokeDasharray="5"/>
            <path id="Path_1476" data-name="Path 1476" d="M60.62,429.815a60.854,60.854,0,0,1,27.337-44.706" fill="none"
                  stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" strokeDasharray="5"/>
            <path id="Path_1477" data-name="Path 1477" d="M152.722,488.192a60.936,60.936,0,0,1-62.807.185" fill="none"
                  stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" strokeDasharray="5"/>
            <circle id="Ellipse_253" data-name="Ellipse 253" cx="33.108" cy="33.108" r="33.108"
                    transform="translate(88.057 353.382)" fill="none" stroke="#baa082" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_505" data-name="Group 505">
                <path id="Path_923" data-name="Path 923" d="M120.979,385.937l-1,1v-11.25h17.779v11.366H126.466"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_131" data-name="Line 131" x2="17.782" transform="translate(119.973 379.402)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_924" data-name="Path 924" d="M119.973,380.9l-7.511,2.017a1.8,1.8,0,0,0-.884.548l-7,7.911"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_925" data-name="Path 925"
                      d="M111.217,397.29h4.461a1.808,1.808,0,0,0,1.279-.529l9.051-9.051a2.4,2.4,0,0,0,0-3.4l0,0h0a2.4,2.4,0,0,0-3.4,0l-4.7,4.7"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_926" data-name="Path 926"
                      d="M129.065,387.058l1.4,2.507a2.113,2.113,0,0,1-.668,2.862h0a2.112,2.112,0,0,1-2.92-.632l-.045-.073-1.788-3.06"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_927" data-name="Path 927"
                      d="M125.053,388.666l1.826,3.114a2.113,2.113,0,0,1-.668,2.862h0a2.112,2.112,0,0,1-2.92-.632l-.044-.073L122,391.717"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_928" data-name="Path 928"
                      d="M122,391.713l1.221,2.188a2.113,2.113,0,0,1-.669,2.861h0a2.11,2.11,0,0,1-2.918-.63c-.016-.024-.031-.049-.046-.074l-.7-1.228"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <circle id="Ellipse_254" data-name="Ellipse 254" cx="33.108" cy="33.108" r="33.108"
                    transform="translate(130.412 423.779)" fill="none" stroke="#baa082" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="1.5"/>
            <circle id="Ellipse_255" data-name="Ellipse 255" cx="33.108" cy="33.108" r="33.108"
                    transform="translate(46.561 423.779)" fill="none" stroke="#baa082" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_506" data-name="Group 506">
                <path id="Path_908" data-name="Path 908" d="M171.157,464.1h-3.218V450.508h8.16l5.177,6.8v6.8h-3.219"
                      fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_909" data-name="Path 909" d="M145.766,446.088h22.173V464.1h-5.253" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_910" data-name="Path 910" d="M155.789,464.1h-4.945v-3.311" fill="none" stroke="#007d7d"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_127" data-name="Line 127" x1="11.278" transform="translate(147.96 450.535)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_128" data-name="Line 128" x1="8.394" transform="translate(150.844 455.094)" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_62" data-name="Ellipse 62" cx="3.449" cy="3.449" r="3.449"
                        transform="translate(155.789 460.788)" fill="none" stroke="#007d7d" strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_63" data-name="Ellipse 63" cx="3.449" cy="3.449" r="3.449"
                        transform="translate(171.158 460.788)" fill="none" stroke="#007d7d" strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <g id="Customisation_icon" data-name="Customisation icon">
                <g id="Group_486" data-name="Group 486">
                    <path id="Path_934" data-name="Path 934" d="M84.653,456.8l8.436,8.436-4.07,4.07L80.4,460.689"
                          fill="none" stroke="#007d7d" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_935" data-name="Path 935" d="M76.755,457.044l-8.5-8.505,4.07-4.07,8.23,8.23"
                          fill="none" stroke="#007d7d" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_135" data-name="Line 135" x1="2.333" y2="2.333" transform="translate(73.017 447.081)"
                          fill="none" stroke="#007d7d" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_136" data-name="Line 136" x1="2.217" y2="2.217" transform="translate(75.923 450.161)"
                          fill="none" stroke="#007d7d" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_137" data-name="Line 137" x1="2.281" y2="2.281" transform="translate(85.087 459.446)"
                          fill="none" stroke="#007d7d" strokeLinejoin="round" strokeWidth="1.5"/>
                    <line id="Line_138" data-name="Line 138" x1="2.333" y2="2.333" transform="translate(87.959 462.325)"
                          fill="none" stroke="#007d7d" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
                <g id="Group_487" data-name="Group 487">
                    <path id="Path_936" data-name="Path 936"
                          d="M78.122,462.774l-3.436-3.362A5.351,5.351,0,0,0,69.9,464.3c-.3,3.5-2.577,3.592-3.564,3.461a.081.081,0,0,0-.089.069.079.079,0,0,0,.039.079C67.914,468.8,76.108,471.413,78.122,462.774Z"
                          fill="none" stroke="#007d7d" strokeMiterlimit="10" strokeWidth="1.5"/>
                    <path id="Path_937" data-name="Path 937"
                          d="M91.492,445.662h0a3.717,3.717,0,0,0-5.233.487q-.05.059-.1.12L74.684,459.408l3.436,3.36L91,451a3.717,3.717,0,0,0,.583-5.223C91.555,445.736,91.524,445.7,91.492,445.662Z"
                          fill="none" stroke="#007d7d" strokeMiterlimit="10" strokeWidth="1.5"/>
                </g>
            </g>
        </g>
    </svg>
)

export const SaveTimeIcon = ({className}) => (
    <svg id="save_time_icon" xmlns="http://www.w3.org/2000/svg" width="155" height="155" viewBox="0 0 155 155"
         className={className}>
        <g id="Group_835" data-name="Group 835" transform="translate(-466.982 -343.2)">
            <g id="Group_832" data-name="Group 832">
                <path id="Path_1502" data-name="Path 1502" d="M521.115,411.523V392.049h19.517v25.615H531.5" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <ellipse id="Ellipse_271" data-name="Ellipse 271" cx="3.326" cy="3.33" rx="3.326" ry="3.33"
                         transform="translate(529.179 396.602)" fill="#007d7d"/>
                <path id="Path_1503" data-name="Path 1503"
                      d="M521.115,406.137s4.019-1.852,10.6,4.537c2.7,2.623,6.142,1.8,8.919,1.8" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <rect id="Rectangle_745" data-name="Rectangle 745" width="19.517" height="25.615"
                      transform="translate(568.45 417.664) rotate(-180)" fill="none" stroke="#007d7d"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1504" data-name="Path 1504"
                      d="M568.45,398.047s-7.965-.43-9.388,7.84c-1.123,6.527-7.353,6.59-10.13,6.59" fill="none"
                      stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_272" data-name="Ellipse 272" cx="1.663" cy="1.663" r="1.663"
                        transform="translate(561.543 410.488)" fill="#007d7d"/>
            </g>
            <path id="Path_1505" data-name="Path 1505"
                  d="M603.451,465.472V488.2H485.513V414.561l-15.928.05,21.32-18.149h0V361.714H513.33l.21,16.857h0L544.482,353.2l74.9,61.411-15.928.009v7.334"
                  fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_834" data-name="Group 834">
                <line id="Line_313" data-name="Line 313" x1="5.453" y2="3.308" transform="translate(581.307 432.876)"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_314" data-name="Line 314" y1="2.469" x2="6.087" transform="translate(582.136 438.217)"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_315" data-name="Line 315" y1="15.187" x2="2.122" transform="translate(526.117 460.929)"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1506" data-name="Path 1506" d="M512.942,465.524l8.426-3.848.478,16.27" fill="none"
                      stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1507" data-name="Path 1507"
                      d="M508.729,476.166l-7.5-14.253a9.987,9.987,0,0,1-1.092-5.722l.26-1.994" fill="none"
                      stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1508" data-name="Path 1508"
                      d="M521.132,481.1c0-2.753.95-4.985,4.985-4.985,4.4,0,8.846,2.232,8.846,4.985Z" fill="none"
                      stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1509" data-name="Path 1509"
                      d="M496.372,474.561c-.032,1.214-.154,2.285.351,2.452,2.49.822,3.1,2.345,3.1,4.088h-6.568l-3.078-5.664c0-2.386-.469-4.359,2.542-4.687"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Ellipse_67" data-name="Ellipse 67" d="M518.324,415.408a7.238,7.238,0,1,0,11.865,5.266"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Ellipse_67-2" data-name="Ellipse 67" d="M530.872,420.977a7.238,7.238,0,0,0-13.231-5.871"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1027" data-name="Path 1027" d="M525.005,423.587a3.838,3.838,0,0,0,2.327,3.127"
                      fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_316" data-name="Line 316" x2="17.505" y2="7.768" transform="translate(517.641 415.106)"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1510" data-name="Path 1510"
                      d="M521.376,449.2l2.61-5.882c2.862-6.448.971-13.543-4.222-15.847s-11.723,1.054-14.584,7.5L498.869,449.2a3.993,3.993,0,0,0,2.031,5.271l11.5,5.106a4,4,0,0,0,5.271-2.031l1.481-3.337"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_317" data-name="Line 317" x2="6.568" y2="5.454" transform="translate(524.345 442.522)"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_318" data-name="Line 318" x1="4.026" y1="2.375" transform="translate(522.091 447.591)"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Ellipse_67-3" data-name="Ellipse 67" d="M596.633,406.747a7.237,7.237,0,1,1-12.51,3.462"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Ellipse_67-4" data-name="Ellipse 67" d="M583.4,410.409a7.238,7.238,0,1,1,13.951-3.86"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1027-2" data-name="Path 1027" d="M588.821,413.853a3.837,3.837,0,0,1-2.782,2.756"
                      fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_319" data-name="Line 319" x1="18.458" y2="5.107" transform="translate(578.896 406.549)"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1511" data-name="Path 1511"
                      d="M597.675,481.633c0-2.753-.95-4.985-4.985-4.985-4.4,0-8.846,2.232-8.846,4.985Z" fill="none"
                      stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1512" data-name="Path 1512"
                      d="M618.844,477.965c0-2.615.564-4.735-3.513-4.735-4.352,0-2.79,3.9-4.035,4.314-2.49.822-4.367,2.345-4.367,4.089H613.5Z"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1513" data-name="Path 1513"
                      d="M612.707,474.162,601.85,463.969a4,4,0,0,1-1.257-3.082l.469-10.988" fill="none" stroke="#baa082"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1514" data-name="Path 1514"
                      d="M609.276,447.626l-1.543,10.838a3.991,3.991,0,0,0,.714,2.9l8.706,12.077" fill="none"
                      stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1515" data-name="Path 1515"
                      d="M592.085,476.648l-3.37-15.451a4,4,0,0,1,.329-2.637l3.836-7.678" fill="none" stroke="#baa082"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1516" data-name="Path 1516"
                      d="M600.854,454.761l-4.469,6.009a4,4,0,0,0-.781,2.651l.974,14.561" fill="none" stroke="#baa082"
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <g id="Group_833" data-name="Group 833">
                    <path id="Path_1517" data-name="Path 1517"
                          d="M582.563,444.839l-.6-5.823c-.585-5.687-5.174-9.874-10.249-9.352l-33.05,3.4c-5.075.522-8.715,5.556-8.13,11.244l.6,5.821"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1518" data-name="Path 1518"
                          d="M586.443,443.489a5.119,5.119,0,0,0-.917.013,5.172,5.172,0,0,0-4.583,4.369" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1519" data-name="Path 1519" d="M586.585,453.8a5.173,5.173,0,0,0,4.632-4.837"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1520" data-name="Path 1520" d="M533.532,455.733a5.172,5.172,0,0,0-9.836-3.1"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1521" data-name="Path 1521" d="M539.636,471.963l2.869-.3,1.482-5.8-6.983.719Z"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1522" data-name="Path 1522" d="M575.816,468.241l2.869-.3,1.482-5.8-6.983.718Z"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_13" data-name="Path 13" d="M570.731,439.019l-2.647,2.412,3.085,1.848" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_13-2" data-name="Path 13" d="M563.757,439.737l3.082,1.822L564.2,444" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_13-3" data-name="Path 13" d="M548.508,441.3l-2.647,2.413,3.086,1.847" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_13-4" data-name="Path 13" d="M541.534,442.023l3.083,1.823-2.645,2.437" fill="none"
                          stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_1523" data-name="Path 1523"
                          d="M581.533,451.078l-48.67,5.008a4.193,4.193,0,0,0-3.742,4.6l.253,2.464a4.194,4.194,0,0,0,4.6,3.743l49.22-5.064a4.194,4.194,0,0,0,3.743-4.6l-.253-2.463a4.181,4.181,0,0,0-1.34-2.664"
                          fill="none" stroke="#007d7d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
                <path id="Path_1524" data-name="Path 1524"
                      d="M588.734,440.065l-1.81-6.542c-1.881-6.8,1.033-13.539,6.509-15.054s11.44,2.769,13.321,9.567l4.149,14.995a4,4,0,0,1-2.785,4.915l-12.13,3.356a3.994,3.994,0,0,1-4.915-2.784l-.77-2.782"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_273" data-name="Ellipse 273" cx="3.219" cy="3.219" r="3.219"
                        transform="translate(522.951 455.199)" fill="#fff" stroke="#baa082" strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1525" data-name="Path 1525"
                      d="M522.968,458.258l-9.582-10.19-2.017-10.075a3.434,3.434,0,0,1,2.86-4.071h0a3.434,3.434,0,0,1,3.925,3.057l.732,8.541,6.6,9.756"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_274" data-name="Ellipse 274" cx="3.219" cy="3.219" r="3.219"
                        transform="translate(580.879 445.919)" fill="none" stroke="#baa082" strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1526" data-name="Path 1526"
                      d="M587.3,448.963l8.752-9.4a4,4,0,0,0,1-1.957l1.748-8.965a3.433,3.433,0,0,0-2.88-4.056h0a3.434,3.434,0,0,0-3.91,3.076l-.6,7.509a3.993,3.993,0,0,1-.662,1.9l-5.971,8.925"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1527" data-name="Path 1527"
                      d="M506.325,471.6l-8.942-2.634c-2.859-.594-4.265.375-4.672,1.736h0c0,2.222,2.411,3.4,4.363,4.14.14.053.268.107.385.164l12.6,6.186a4.3,4.3,0,0,0,3.613.084h0a4.305,4.305,0,0,0,2.411-5.173l-3.922-13.271"
                      fill="none" stroke="#baa082" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
        </g>
    </svg>
)

export const CustomisationIcon = ({className}) => (
    <svg height="35" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg" className={className}>
        <g fill="none">
            <g stroke="currentColor" strokeWidth="1.5" transform="translate(-114.673 -444.611)">
                <g strokeLinejoin="round">
                    <path d="m136.787 462.03 7.812 7.812-3.769 3.769-7.979-7.979"/>
                    <path d="m129.474 462.256-7.875-7.875 3.769-3.769 7.621 7.621"/>
                    <path d="m128.172 453.031-2.16 2.16"/>
                    <path d="m130.756 455.883-2.053 2.053"/>
                    <path d="m139.302 464.481-2.113 2.113"/>
                    <path d="m142.009 467.147-2.16 2.16"/>
                </g>
                <g strokeMiterlimit="10">
                    <path
                        d="m130.74 467.562-3.182-3.113a4.955 4.955 0 0 0 -4.428 4.527c-.278 3.244-2.386 3.326-3.3 3.2a.074.074 0 0 0 -.047.137c1.504.828 9.092 3.249 10.957-4.751z"/>
                    <path
                        d="m143.12 451.717a3.442 3.442 0 0 0 -4.846.451c-.03.036-.059.073-.088.111l-10.63 12.166 3.182 3.112 11.928-10.9a3.442 3.442 0 0 0 .54-4.836c-.028-.035-.057-.07-.086-.104z"/>
                </g>
            </g>
        </g>
    </svg>
)

export const ConfirmationIcon = ({className}) => (
    <svg id="confirmation_icon_grey" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
         className={className}>
        <g id="Orders_icon" data-name="Orders icon" transform="translate(-56.865 -444.879)">
            <path id="Path_929" data-name="Path 929"
                  d="M77.492,471.466l-1.677,1.677-2.254-2.254-2.254,2.254-2.254-2.254L66.8,473.143V453.372" fill="none"
                  stroke="currentColor" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_930" data-name="Path 930" d="M64.554,451.379H78.106a2.224,2.224,0,0,1,2.225,2.223h0V463.5"
                  fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.5"/>
            <line id="Line_132" data-name="Line 132" x2="6.974" transform="translate(70.127 455.339)" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <line id="Line_133" data-name="Line 133" x2="3.487" transform="translate(70.127 458.521)" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <line id="Line_134" data-name="Line 134" x2="5.299" transform="translate(70.127 461.703)" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_484" data-name="Group 484">
                <circle id="Ellipse_64" data-name="Ellipse 64" cx="5.002" cy="5.002" r="5.002"
                        transform="translate(76.421 463.375)" fill="none" stroke="currentColor" strokeMiterlimit="10"
                        strokeWidth="1.5"/>
                <path id="Path_931" data-name="Path 931" d="M78.934,468.512l1.642,1.642,3.005-3.006" fill="none"
                      stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <path id="Path_932" data-name="Path 932" d="M62.307,453.626a2.247,2.247,0,0,1,4.493,0" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_933" data-name="Path 933" d="M66.8,453.626v5.65H62.306v-5.65" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        </g>
    </svg>
)

export const DeliveryIcon = ({className}) => (
    <svg id="delivery_icon_grey" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
         className={className}>
        <g id="Group_478" data-name="Group 478" transform="translate(-175.584 -446.101)">
            <path id="Path_908" data-name="Path 908" d="M200.155,470.279h-2.98V457.694h7.556l4.794,6.293v6.293h-2.981"
                  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_909" data-name="Path 909" d="M176.643,453.6h20.532V470.28h-4.864" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_910" data-name="Path 910" d="M185.924,470.279h-4.579v-3.066" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <line id="Line_127" data-name="Line 127" x1="10.443" transform="translate(178.675 457.719)" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <line id="Line_128" data-name="Line 128" x1="7.773" transform="translate(181.345 461.94)" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <circle id="Ellipse_62" data-name="Ellipse 62" cx="3.194" cy="3.194" r="3.194"
                    transform="translate(185.924 467.213)" fill="none" stroke="currentColor" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="1.5"/>
            <circle id="Ellipse_63" data-name="Ellipse 63" cx="3.194" cy="3.194" r="3.194"
                    transform="translate(200.156 467.213)" fill="none" stroke="currentColor" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="1.5"/>
        </g>
    </svg>
)

export const ReturnsIcon = ({className}) => (
    <svg id="refunds_icon_grey" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
         className={className}>
        <g id="Group_511" data-name="Group 511" transform="translate(-401.59 -446.224)">
            <line id="Line_182" data-name="Line 182" x2="6.649" transform="translate(413.493 471.989)" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1028" data-name="Path 1028" d="M411.619,453.223l-4.457,4.457,4.457,4.457" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1029" data-name="Path 1029" d="M407.162,457.681h15.245c4.231,0,7.146,2.379,7.146,5.668"
                  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <rect id="Rectangle_418" data-name="Rectangle 418" width="10.875" height="10.875"
                  transform="translate(420.142 463.349)" fill="none" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="1.5"/>
            <rect id="Rectangle_419" data-name="Rectangle 419" width="3.346" height="4.183"
                  transform="translate(423.906 463.349)" fill="none" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="1.5"/>
        </g>
    </svg>
)

export const PaymentIcon = ({className}) => (
    <svg id="payment_icon_grey" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
         className={className}>
        <g id="Group_481" data-name="Group 481" transform="translate(-239.222 -446.168)">
            <path id="Path_923" data-name="Path 923" d="M256.549,463.155l-.93.931V453.668h16.463v10.525H261.63"
                  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <line id="Line_131" data-name="Line 131" x2="16.466" transform="translate(255.618 457.104)" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_924" data-name="Path 924"
                  d="M255.618,458.488l-6.955,1.868a1.668,1.668,0,0,0-.819.507l-6.484,7.326" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_925" data-name="Path 925"
                  d="M247.51,473.668h4.131a1.674,1.674,0,0,0,1.184-.49l8.381-8.381a2.228,2.228,0,0,0,0-3.15l0,0h0a2.227,2.227,0,0,0-3.145,0l-4.351,4.349"
                  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_926" data-name="Path 926"
                  d="M264.037,464.193l1.3,2.322a1.957,1.957,0,0,1-.619,2.65h0a1.956,1.956,0,0,1-2.7-.586c-.014-.022-.028-.044-.041-.067l-1.656-2.833"
                  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_927" data-name="Path 927"
                  d="M260.322,465.682l1.691,2.884a1.957,1.957,0,0,1-.619,2.65h0a1.955,1.955,0,0,1-2.7-.586c-.015-.022-.029-.044-.042-.067l-1.152-2.056"
                  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_928" data-name="Path 928"
                  d="M257.5,468.5l1.13,2.026a1.956,1.956,0,0,1-.619,2.649h0a1.956,1.956,0,0,1-2.7-.583l-.042-.069-.651-1.137"
                  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        </g>
    </svg>
)

export const AddressIcon = ({className}) => (
    <svg id="address_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
         className={className}>
        <g id="Group_625" data-name="Group 625" transform="translate(-667.063 -401.236)">
            <circle id="Ellipse_97" data-name="Ellipse 97" cx="2.39" cy="2.39" r="2.39"
                    transform="translate(682.173 416.335)" fill="none" stroke="#baa082" strokeLinejoin="round"
                    strokeWidth="1.5"/>
            <path id="Path_1052" data-name="Path 1052"
                  d="M677.052,414.9v-4.871H680.2l.029,2.363h0l4.338-3.557,10.5,8.609H692.83v11.187H676.3v-11.2l-2.233.007,2.989-2.544Z"
                  fill="none" stroke="#baa082" strokeLinejoin="round" strokeWidth="1.5"/>
        </g>
    </svg>
)

export const EmailIcon = ({className}) => (
    <svg id="email_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
         className={className}>
        <g id="Group_570" data-name="Group 570" transform="translate(-601.068 -404.592)">
            <path id="Path_1035" data-name="Path 1035"
                  d="M626.512,420.79l-9.718-5.569-5.132,5.132,5.569,9.718,12.8,3.521Z" fill="none" stroke="#baa082"
                  strokeLinejoin="round" strokeWidth="1.5"/>
            <line id="Line_206" data-name="Line 206" x1="7.643" y1="7.643" transform="translate(622.39 425.949)"
                  fill="none" stroke="#baa082" strokeMiterlimit="10" strokeWidth="1.5"/>
            <circle id="Ellipse_84" data-name="Ellipse 84" cx="1.158" cy="1.158" r="1.158"
                    transform="translate(620.413 423.972)" fill="none" stroke="#baa082" strokeMiterlimit="10"
                    strokeWidth="1.5"/>
            <line id="Line_207" data-name="Line 207" x1="4.559" y1="2.484" transform="translate(607.104 417.869)"
                  fill="none" stroke="#baa082" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5"/>
            <line id="Line_208" data-name="Line 208" x1="2.484" y1="4.559" transform="translate(614.381 410.592)"
                  fill="none" stroke="#baa082" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5"/>
        </g>
    </svg>
)

export const PhoneIcon = ({className}) => (
    <svg id="phone_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
         className={className}>
        <g id="Group_569" data-name="Group 569" transform="translate(-520.757 -404.592)">
            <path id="Path_1031" data-name="Path 1031"
                  d="M533.51,431.762c-.15-.091-.3-.189-.45-.272a8.039,8.039,0,0,1-1.742-1.322,12.743,12.743,0,0,1-2.126-2.763,18.611,18.611,0,0,1-1.265-2.531,27.066,27.066,0,0,1-1.262-3.826,16.7,16.7,0,0,1-.43-2.647,9.971,9.971,0,0,1,.106-2.434,7.215,7.215,0,0,1,.693-2.055,5.584,5.584,0,0,1,1.489-1.817,4.493,4.493,0,0,1,1.43-.824c.708-.223,1.416-.457,2.143-.622a1.413,1.413,0,0,1,1.659.881,11.614,11.614,0,0,1,.779,3.714,1.825,1.825,0,0,1-1.585,1.982c-.507.114-1.018.221-1.51.375a1.32,1.32,0,0,0-.952,1.018,4.954,4.954,0,0,0,.207,2.52c.2.686.451,1.362.687,2.04a7.926,7.926,0,0,0,1.439,2.594,1.752,1.752,0,0,0,1.74.717,2.682,2.682,0,0,0,.28-.083c.592-.176,1.174-.386,1.779-.52a1.98,1.98,0,0,1,1.877.643,6.572,6.572,0,0,1,1.17,1.721c.175.34.377.67.536,1.016a3.06,3.06,0,0,1,.372,1.679l-.163.383a1.882,1.882,0,0,1-.889.544c-.4.119-.794.249-1.193.37a2.269,2.269,0,0,1-.4.089c-.49.053-.98.117-1.474.137a4.917,4.917,0,0,1-1.672-.244c-.174-.054-.351-.1-.526-.144Z"
                  fill="none" stroke="#baa082" strokeMiterlimit="10" strokeWidth="1.5"/>
            <path id="Path_1032" data-name="Path 1032"
                  d="M546.721,430.9c0,1.513-1.765,1.824-1.765,1.824s-1.765-.332-1.765-1.824a1.766,1.766,0,1,1,3.53-.119,1.172,1.172,0,0,1,0,.119Z"
                  fill="none" stroke="#baa082" strokeMiterlimit="10" strokeWidth="1.5"/>
            <path id="Path_1033" data-name="Path 1033" d="M544.955,432.725a5.9,5.9,0,0,0,5.354-.526" fill="none"
                  stroke="#baa082" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5"/>
            <path id="Path_1034" data-name="Path 1034" d="M538.466,432.2a5.369,5.369,0,0,0,6.49.526" fill="none"
                  stroke="#baa082" strokeMiterlimit="10" strokeWidth="1.5"/>
        </g>
    </svg>
)
export const ProfileIcon = ({className}) => (
    <svg id="profile_icon_grey" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
         className={className}>
        <g id="Profile_icon" data-name="Profile icon" transform="translate(-351.624 -444.452)">
            <circle id="Ellipse_67" data-name="Ellipse 67" cx="5.762" cy="5.762" r="5.762"
                    transform="translate(363.371 450.452)" fill="none" stroke="currentColor" strokeMiterlimit="10"
                    strokeWidth="1.5"/>
            <path id="Path_1027" data-name="Path 1027" d="M371.78,457.455a3.054,3.054,0,0,1-5.286,0" fill="none"
                  stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.5"/>
            <path id="Path_1028" data-name="Path 1028" d="M357.648,473.452a11.476,11.476,0,0,1,22.952-.014v.014"
                  fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.5"/>
            <path id="Path_1029" data-name="Path 1029" d="M365.117,462.87l3.956,5.868,3.956-5.868" fill="none"
                  stroke="currentColor" strokeLinejoin="round" strokeWidth="1.5"/>
        </g>
    </svg>
)

export const CartIcon = ({className}) => (
    <svg id="cart_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" className={className}>
        <g id="Group_1105" data-name="Group 1105" transform="translate(-357.395 -353.273)">
            <path id="Path_1546" data-name="Path 1546" d="M380.625,368.461" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1547" data-name="Path 1547" d="M381.493,366.812c.839-1.339,1.831-2.826,1.162-3.886h0L383.968,361l-5-1.571.014,2.343h0c-1.463.616-1.134,3.318-1.364,5.04Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <g id="Group_1104" data-name="Group 1104">
                <path id="Path_1548" data-name="Path 1548" d="M373.735,361.035l-6.16,1.543-.186-5.04,4.133-1.036Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_1549" data-name="Path 1549" d="M374.472,366.812a2.6,2.6,0,1,0-5.186,0Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_403" data-name="Line 403" x2="0.561" y2="2.141" transform="translate(370.687 362.011)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <path id="Path_1550" data-name="Path 1550" d="M382.022,380.046H368.05l-3.027-15.2h-4.188" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <circle id="Ellipse_361" data-name="Ellipse 361" cx="2.041" cy="2.041" r="2.041" transform="translate(368.783 380.961)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <circle id="Ellipse_362" data-name="Ellipse 362" cx="2.041" cy="2.041" r="2.041" transform="translate(377.592 380.961)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path id="Path_1551" data-name="Path 1551" d="M367.286,376.21h15.452l2.217-9.4h-19.54" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        </g>
    </svg>
)
