import React from 'react'
import { InstagramIcon, LogoFooter, UAEFlagIcon } from "@/Components/Icons"
import { InertiaLink } from "@inertiajs/inertia-react"

const FooterLink = ({href, children, className}) => (
    <InertiaLink
        href={href}
        className={`text-sm hover:text-black whitespace-no-wrap ${typeof window !== 'undefined' ? window.location?.pathname === href ? 'text-black' : 'text-gray-dark' : ''} ${className}`}
    >
        {children}
    </InertiaLink>
)

const Footer = () => {
    return (
        <footer
            className="bg-gray-light py-10 px-10 laptop:px-25 mt-13 flex flex-col items-center laptop:flex-row laptop:items-start">
            <InertiaLink href="/" className="mb-9 laptop:mb-0">
                <LogoFooter/>
            </InertiaLink>
            <div
                className="laptop:mx-7.5 flex flex-grow justify-center flex-col mb-7.5 laptop:mb-0 laptop:flex-row whitespace-nowrap">
                <div className="flex items-center laptop:items-start flex-col">
                    <FooterLink href="/#browse" className="mb-5 laptop:mr-7.5">
                        Browse rooms
                    </FooterLink>
                    <FooterLink href="/about" className="mb-5 laptop:mr-7.5">
                        About us
                    </FooterLink>
                    <FooterLink href="/get-in-touch" className="laptop:mr-7.5 mb-5">
                        Get in touch
                    </FooterLink>
                    <FooterLink href="/faq" className="laptop:mr-7.5 mb-5 laptop:mb-0">
                        FAQs
                    </FooterLink>
                </div>
                <div className="flex flex-col items-center laptop:items-start laptop:ml-16">
                    {/*<FooterLink href="/returns-and-refunds" className="laptop:mr-7.5 mb-5">*/}
                    {/*    Return & refunds*/}
                    {/*</FooterLink>*/}
                    <FooterLink href="/terms" className="laptop:mr-7.5 mb-5">
                        Terms & conditions
                    </FooterLink>
                    <FooterLink href="/privacy" className="mr-0">
                        Privacy policy
                    </FooterLink>
                </div>
            </div>
            <div className="flex flex-col items-center laptop:items-start laptop:ml-auto">
                <div className="flex items-center mb-5">
                    {/*<InertiaLink href="#" className="text-gray-dark mr-4 hover:text-black">*/}
                    {/*    <FacebookIcon className="fill-current w-6"/>*/}
                    {/*</InertiaLink>*/}
                    <a href="https://www.instagram.com/vibetrayliving/" target="_blank"
                       className="text-gray-dark mr-4 hover:text-black">
                        <InstagramIcon className="fill-current w-6"/>
                    </a>
                    {/*<InertiaLink href="#" className="text-gray-dark hover:text-black">*/}
                    {/*    <PinterestIcon className="fill-current w-6"/>*/}
                    {/*</InertiaLink>*/}
                </div>
                <div className="text-xs flex items-center text-gray-dark mb-3">
                    <span style={{marginRight: 5}}>We operate in the UAE</span>
                    <UAEFlagIcon/>
                </div>
                <div className="text-xs flex items-center text-gray-dark">
                    All images are subject to copyright.
                </div>
            </div>
        </footer>
    )
}
export default Footer
